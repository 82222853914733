<template>
    <!-- <h6 class="heading-small text-muted mb-0 mb-md-2">Informações do Atendimento</h6> -->
    <div v-if="!this.isFinalizar">
      <div class="row">
        <div class="col-10 col-md-10 d-flex align-items-center">
          <img src="@/assets/img/icons/user.png" alt="profile.png" class="card-profile me-3" />
          <h6 class="mb-0 text-sm" id="nomeCliente"> {{ service.cliente.nome }}, {{ service.cliente.telefone }}</h6>
        </div>
        <!-- <div class="col-2 col-md-2 mt-0 mt-md-0 d-flex justify-content-end">
          <button class="btn btn-sm btn-success mt-2 btn-responsive" disabled @click.prevent="exibeAlertaExclusao()"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
        </div> -->
      </div>

      <div class="row mt-1">
        <div class="col-10 col-md-10 d-flex align-items-center">
          <img src="@/assets/img/icons/manager.png" alt="profile.png" class="card-profile me-3" />
          <h6 class="mb-0 text-sm" id="nomeCliente"> {{ service.colaborador.nome }}</h6>
        </div>
        <!-- <div class="col-2 col-md-2 mt-0 mt-md-0 d-flex justify-content-end">
          <button class="btn btn-sm btn-success mt-2 btn-responsive" disabled @click.prevent="exibeAlertaExclusao()"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
        </div> -->
      </div>

      <div class="row mt-1">
        <div class="col-10 col-md-10 mt-0 mt-md-0 d-flex align-items-center">
          <img src="@/assets/img/icons/work.png" alt="profile.png" class="card-profile me-3" />
          <h6 class="mb-0 text-sm" id="nomeCliente"> {{ service.nomeServico }}</h6>
        </div>
        <!-- <div class="col-2 col-md-2 mt-0 mt-md-0 d-flex justify-content-end">
          <button class="btn btn-sm btn-success mt-2 btn-responsive" disabled @click.prevent="exibeAlertaExclusao()"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
        </div> -->
      </div>

      <div class="row mt-1">
        <div class="col-10 col-md-10 d-flex align-items-center">
          <img src="@/assets/img/icons/calendar.png" alt="profile.png" class="card-profile me-3" />
          <h6 class="mb-0 text-sm" id="nomeCliente">{{ this.formatarData(service.data) }} - {{ service.horaInicio }} {{ service.codigoTipoAgendamento == 0 ? ' às ' : '' }} {{ service.horaFimPrevisto }}</h6>
        </div>
      </div>

      <div class="row mt-1" v-if="service.observacao != null">
        <div class="col-10 col-md-10 d-flex align-items-center">
          <img src="@/assets/img/icons/notes.png" alt="profile.png" class="card-profile me-3" />
          <h6 class="mb-0 text-sm text-secondary" id="nomeCliente">Obs: {{ service.observacao }}</h6>
        </div>
      </div>

      <hr class="mt-2">

      <div class="row">
        <div class="col-12 col-md-12 mt-0 mt-md-0 text-center">
          <label for="situacaoServico">Situação:</label>
          <div id="situacaoServico" class="d-flex justify-content-between flex-wrap">
            <button 
              v-for="status in statusOptions" 
              :key="status.value"
              :class="['btn', 'flex-fill', 'm-1', service.situacao === status.label ? 'btn-success' : 'btn-outline-success']" 
              @click="salvarAlteracaoAtendimento(status.label)"
              style="min-width: 80px;">
              {{ status.label }}
            </button>
          </div>
        </div>
      </div>

      <hr class="mt-4">

      <div class="row mt-3" v-if="service.codigoMeioPagamento != null">
        <div class="col-6 col-md-6">
          <h6 class="mb-0 text-sm text-secondary" id="nomeCliente">Forma de Pagamento: </h6>
        </div>
        <div class="col-6 col-md-6 text-end">
          <h6 class="mb-0 text-sm" id="nomeCliente">{{ service.meioPagamento }}</h6>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-4 col-md-4">
          <h5 class="mb-0 text-md" id="nomeCliente"> TOTAL: </h5>
        </div>
        <div class="col-8 col-md-8 text-end">
          <h5 class="mb-0 text-md" id="nomeCliente" style="color: #03a561;"> R$ {{ this.valorFinalComputado }}</h5>
        </div>
      </div>

      <hr class="mt-3">

      <div class="row">
        <div class="col-12 col-md-12 mt-0 mt-md-0 text-center">
          <div id="situacaoServico" class="d-flex justify-content-between flex-wrap">
            <button type="submit" @click="this.visualizar(this.atendimentoId)" class="btn flex-fill m-1 btn-primary" style="margin-left: 5px;"
              variant="success">Alterar Dados</button>
            <button type="submit" :hidden="service.situacao == 'Concluído'" @click="this.finalizar(this.atendimentoId)" class="btn flex-fill m-1 btn-success" style="margin-left: 5px;"
              variant="success">Finalizar</button>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-2 col-md-2 text-left">
          <a href="#!" class="btn btn-sm btn-primary btn-responsive" @click="goBack"><i class="fa fa-chevron-left"></i></a>
        </div>
        <div class="col-10 col-md-10 d-flex justify-content-end">
          <a href=" #!" class="btn btn-sm btn-danger btn-responsive" @click.prevent="exibeAlertaExclusao()"><i class="fa fa-trash"></i></a>
        </div>
      </div>

      
    </div>
    <div v-if="this.isFinalizar">
      <form @submit.prevent="finalizarAtendimento">
        <h6 class="heading-small text-muted mb-0 mb-md-2">Informações do Atendimento</h6>
        <div>
          <div class="row">
            <div class="col-6 col-md-6 mt-3 mt-md-0">
              <label for="horaFimServico">Horário Fim Atendimento</label>
              <input type="time" id="horaFimServico" v-model="service.horaFimPrevisto" class="form-control"
                placeholder="hh:mm">
            </div>
            <div class="col-6 col-md-6 mt-3 mt-md-0">
              <label for="valorAdicional">Valor Adicional (R$)</label>
              <money3 class="form-control" id="valorAdicional" v-model="service.valorAdicional" v-bind="config"></money3>
            </div>
            <div class="col-6 col-md-6 mt-3 mt-md-0">
              <label for="desconto">Desconto (R$)</label>
              <money3 class="form-control" v-model="service.desconto" v-bind="config"></money3>
            </div>
            <div class="col-6 col-md-6 mt-3 mt-md-0">
              <label for="valorFinal">Valor Final (R$)</label>
                <money3 class="form-control" id="valorFinal" v-model="valorFinalComputado" v-bind="config2"></money3>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-12 mt-3 mt-md-0">
              <label for="formaPagamento">Forma de Pagamento</label>
              <select id="formaPagamento" v-model="service.codigoMeioPagamento" class="form-control">
                <option v-for="meioPagamento in formaPagamentoOptions" :key="meioPagamento.value" :value="meioPagamento.value">
                  {{ meioPagamento.label }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-12 mt-3 mt-md-0">
              <label for="observacaoServico">Observação</label>
              <textarea id="observacaoServico" v-model="service.observacao" class="form-control" rows="3"></textarea>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-2 col-md-2 text-left">
              <a href="#!" class="btn btn-sm btn-primary btn-responsive" @click="goBackFinalizar"><i class="fa fa-chevron-left"></i></a>
            </div>
            <div class="col-10 col-md-10 d-flex justify-content-end">
              <a href=" #!" class="btn btn-sm btn-danger btn-responsive" @click.prevent="exibeAlertaExclusao()"><i class="fa fa-trash"></i></a>
              <button type="submit" class="btn btn-sm btn-success ml-2 btn-responsive" style="margin-left: 5px;"
                variant="success">FINALIZAR</button>
            </div>
          </div>
        </div>
      </form>
    </div>


  <div class="modal fade" ref="modalVisualizarAtendimento" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Resumo do Atendimento</h5>
          <button type="button" class="btn-close" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <VisualizarAtendimentoForm :atendimentoId="this.atendimentoId" :closeModal="closeModal"
            :modalOpened="modalVisualizarAtendimentoOpened" />
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import VisualizarAtendimentoForm from "../atendimentos/VisualizarAtendimentoForm.vue";
// import FinalizarAtendimentoWizardForm from './FinalizarAtendimentoWizardForm.vue';
import { Modal } from 'bootstrap';
import { Money3Component } from 'v-money3';


export default {
  components: {
    VisualizarAtendimentoForm,
    money3: Money3Component
    // FinalizarAtendimentoWizardForm
  },
  props: {
    atendimentoId: {
      type: Number,
    },
    modalOpened: {
      type: Boolean
    },
    closeModal: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('userData')),
      infoMessage: '',
      errorMessage: '',
      modalVisualizarAtendimento: null,
      modalVisualizarAtendimentoOpened: false,
      isFinalizar: false,
      service: {
        id: null,
        nomeServico: '',
        data: '',
        horaInicio: '',
        horaFimPrevisto: '',
        valorAdicional: 0,
        preco: 0,
        desconto: 0,
        valorFinal: 0,
        situacao: '',
        formaPagamento: '',
        codigoMeioPagamento: 0,
        codigoTipoAgendamento: null,
        observacao: '',
        cliente: {
          id: null,
          nome: '',
          telefone: ''
        },
        colaborador: {
          id: null,
          nome: '',
          telefone: ''
        }
      },
      statusOptions: [
        { value: 0, label: 'Agendado' },
        { value: 1, label: 'Iniciado' },
        { value: 2, label: 'Concluído' },
        { value: 3, label: 'Cancelado - Cliente' },
        { value: 4, label: 'Cancelado - Colaborador' },
        { value: 5, label: 'Cliente faltou' }
      ],
      formaPagamentoOptions: [
        { value: 0, label: 'Dinheiro em Espécie' },
        { value: 1, label: 'PIX' },
        { value: 2, label: 'Cartão de Crédito - AVISTA' },
        { value: 3, label: 'Cartão de Crédito - PARCELADO' },
        { value: 4, label: 'Boleto' }
      ],
      showAlert: false,
      showAlertError: false,
      selectedService: null,
      selectedClient: null,
      selectedEmployee: null,
      clientOptions: [],
      employeeOptions: [],
      serviceOptions: [],
      loading: false,
      config: {
        masked: false,
        prefix: '',
        suffix: '',
        thousands: ',',
        decimal: '.',
        precision: 2,
        disableNegative: true,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
      config2: {
        masked: false,
        prefix: '',
        suffix: '',
        thousands: ',',
        decimal: '.',
        precision: 2,
        disableNegative: true,
        disabled: true,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      })
    };
  },
  mounted() {
    document.addEventListener("keydown", this.handleEscClose);
    this.modalVisualizarAtendimento = new Modal(this.$refs.modalVisualizarAtendimento);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleEscClose);
  },
  methods: {
    handleEscClose(event) {
      if (event.key === "Escape" && this.modalOpened) {
        this.goBack();
      }
    },
    goBack() {
      this.modalVisualizarAtendimento.hide();
      this.modalVisualizarAtendimentoOpened = false;
      this.closeModal();
    },
    goBackFinalizar() {
      this.isFinalizar = false;
    },
    visualizar(atendimentoId) {
      if (atendimentoId) {
        this.modalVisualizarAtendimento.show();
        this.modalVisualizarAtendimentoOpened = true;
      }
    },
    finalizar(atendimentoId) {
      console.log(atendimentoId);
      this.isFinalizar = true;
    },
    fetchClientesFiltro(searchQuery) {
      if (!searchQuery) {
        this.clientOptions = [];
        return;
      }
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/clientes/empresa`, {
        params: {
          idEmpresa: this.user.idEmpresa,
          nome: searchQuery
        },
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
        this.clientOptions = response.data.clientes.map(cliente => ({
          value: cliente.id,
          label: `${cliente.nome} | Contato: ${cliente.telefone || ''}`
        }));
        this.loading = false;
      }).catch(error => {
        console.error('Erro ao buscar clientes:', error);
        this.clientOptions = [];
        this.loading = false;
      });
    },
    fetchColaboradoresFiltro(searchQuery) {
      if (!searchQuery) {
        this.employeeOptions = [];
        return;
      }
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaboradores/empresa`, {
        params: {
          idEmpresa: this.user.idEmpresa,
          nome: searchQuery
        },
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
        this.employeeOptions = response.data.colaboradores.map(colaborador => ({
          value: colaborador.id,
          label: `${colaborador.nome} | Contato: ${colaborador.telefone || ''}`
        }));
        this.loading = false;
      }).catch(error => {
        console.error('Erro ao buscar colaboradores:', error);
        this.employeeOptions = [];
        this.loading = false;
      });
    },
    fetchServicosColaborador() {
      this.loading = true;
      return axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaborador_servicos`, {
        params: {
          idColaborador: this.selectedEmployee,
          pageNo: 0,
          pageSize: 100
        },
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
        this.serviceOptions = response.data.servicos.map(servico => ({
          value: servico.id,
          label: servico.nomeServico,
        }));
        this.loading = false;
      }).catch(error => {
        console.error('Erro ao buscar serviços:', error);
        this.serviceOptions = [];
        this.loading = false;
      });
    },
    onEmployeeChange() {
      this.selectedService = null;
      this.serviceOptions = [];
    },
    async salvarAlteracaoAtendimento(label) {
      console.log(label)
      if(label == 'Concluído'){
        this.isFinalizar = true;
      }else{
        const dataSelecionada = new Date(this.service.data);
      const formattedDate = dataSelecionada.toISOString().split('T')[0].split('-').reverse().join('/');

      const payload = {
        idServicoAgendado: this.service.id,
        idCliente: this.selectedClient,
        idColaboradorServico: this.selectedService,
        valorAdicional: parseFloat(this.service.valorAdicional) || 0.0,
        desconto: parseFloat(this.service.desconto) || 0.0,
        statusAgendamento: label,
        codigoMeioPagamento: this.service.codigoMeioPagamento,
        data: formattedDate,
        horaInicio: this.service.horaInicio,
        horaFim: this.service.horaFimPrevisto,
        observacao: this.service.observacao
      };

      axios.put(`${process.env.VUE_APP_API_ENDPOINT}/agenda/atualizar_servico_agenda`, payload,{
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
          if (response.status === 200) {
            this.exibeAlerta('success', "Atendimento atualizado com sucesso!")
            this.goBack();
          }
        })
        .catch(error => {
          console.error("Erro ao atualizar o atendimento:", error);
          this.errorMessage = "Erro ao atualizar o atendimento:";
          this.showAlertError = true;
          setTimeout(() => {
            this.showAlertError = false;
          }, 10000);
        });
      }
      
    },
    async finalizarAtendimento() {
      const dataSelecionada = new Date(this.service.data);
      const formattedDate = dataSelecionada.toISOString().split('T')[0].split('-').reverse().join('/');

      const payload = {
        idServicoAgendado: this.service.id,
        idCliente: this.selectedClient,
        idColaboradorServico: this.selectedService,
        valorAdicional: parseFloat(this.service.valorAdicional) || 0.0,
        desconto: parseFloat(this.service.desconto) || 0.0,
        statusAgendamento: 'Concluído',
        codigoMeioPagamento: this.service.codigoMeioPagamento,
        data: formattedDate,
        horaInicio: this.service.horaInicio,
        horaFim: this.service.horaFimPrevisto,
        observacao: this.service.observacao
      };

      axios.put(`${process.env.VUE_APP_API_ENDPOINT}/agenda/atualizar_servico_agenda`, payload,{
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
          if (response.status === 200) {
            this.exibeAlerta('success', "Atendimento atualizado com sucesso!")
            this.goBack();
            this.isFinalizar = false;
          }
        })
        .catch(error => {
          console.error("Erro ao atualizar o atendimento:", error);
          this.errorMessage = "Erro ao atualizar o atendimento:";
          this.showAlertError = true;
          setTimeout(() => {
            this.showAlertError = false;
          }, 10000);
        });
    },
    async excluirAtendimento() {
      axios.delete(`${process.env.VUE_APP_API_ENDPOINT}/agenda`, {
        params: {
          servicoId: this.atendimentoId
        },
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
          if (response.status === 204) {
            return true;
          }
        })
        .catch(error => {
          console.error("Erro ao deletar o atendimento:", error);
          return false;
        });
    },
    exibeAlerta(tipo, texto){
      this.toast.fire({
        icon: tipo,
        title: texto
      });
    },
    exibeAlertaExclusao(){
      Swal.fire({
        title: "Deseja realmente excluir?",
        text: "A exclusão não poderá ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, excluir!"
      }).then((result) => {
        if (result.isConfirmed) {
          if(this.excluirAtendimento()){
            Swal.fire({
              title: "Excluído!",
              text: "Atendimento excluído com sucesso.",
              icon: "success"
            });
            this.goBack()
          }else{
            Swal.fire({
              title: "Falha na exclusão!",
              text: "Falha ao excluir atendimentos.",
              icon: "error"
            });
          }
          this.goBack();
        }
      });
    },
    fetchServiceDetails() {
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/agenda/recuperar_servico_agendado?servicoId=${this.atendimentoId}`, {
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
          const servico = response.data;
          this.service = servico;
          this.selectedClient = servico.cliente.id;
          this.selectedEmployee = servico.colaborador.id;

          this.fetchClientesFiltro(servico.cliente.nome);
          this.fetchColaboradoresFiltro(servico.colaborador.nome);
          this.fetchServicosColaborador(servico.nomeServico);

          this.service.data = this.formatDate(servico.data);
          
          this.selectedService = servico.idServicoPrincipal;
        })
        .catch(error => {
          console.error('Erro ao buscar detalhes do serviço: ', error);
        });
    },
    formatDate(dateStr) {
      const [day, month, year] = dateStr.split('/');
      return `${year}-${month}-${day}`;
    },
    formatarData(data) {
      if (!data) return '';
        const dataObj = new Date(data);
        const dia = String(dataObj.getDate()).padStart(2, '0');
        const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
        const ano = dataObj.getFullYear();
        return `${dia}/${mes}/${ano}`;
      }
  },
  computed: {
    valorFinalComputado() {
      const valorBase = parseFloat(this.service.preco) || 0;
      const valorAdicional = parseFloat(this.service.valorAdicional) || 0;
      const desconto = parseFloat(this.service.desconto) || 0;

      const valorFinal = (valorBase + valorAdicional) - desconto;

      return valorFinal.toFixed(2);
    }
  },
  watch: {
    modalOpened(newValue) {
      if (newValue) {
        this.fetchServiceDetails();
      } else {
        this.goBack();
      }
    }
  }
}

</script>

<style scoped>
.el-select-v2 {
  width: 100%;
}

.profile-container {
  display: flex;
  align-items: center;
}

.card-profile {
  margin-right: 10px; 
  width: 20px;
  height: 20px;
  object-fit: cover;
}
</style>
