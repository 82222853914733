<script setup>
import { ref, onBeforeUnmount, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Navbar from '@/examples/PageLayout/Navbar.vue';
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonSwitch from '@/components/ArgonSwitch.vue';
import ArgonButton from '@/components/ArgonButton.vue';

const store = useStore();
const router = useRouter();
const body = document.getElementsByTagName('body')[0];

// Estado local para o login
const model = ref({
  email: '',
  password: '',
  rememberMe: false,
});
const error = ref(null);

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove('bg-gray-100');
});

onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add('bg-gray-100');
});

// Método de envio de login
const onSubmit = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/usuarios/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        telefone: model.value.email,
        senha: model.value.password,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      saveUserDataToLocalStorage(data);
      router.push('/dashboard');
    } else {
      error.value = data.descricao;
    }
  } catch (err) {
    console.error('Erro ao realizar a solicitação:', err);
    error.value = 'Erro desconhecido ao realizar login';
  }
};

const saveUserDataToLocalStorage = (data) => {
  localStorage.setItem('userData', JSON.stringify(data));
};
</script>

<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow" :darkMode="true"
          isBtn="bg-gradient-success" />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Login</h4>
                  <p class="mb-0">Entre com seu usuário e senha</p>
                </div>
                <div class="card-body">
                  <form @submit.prevent="onSubmit">
                    <div class="mb-3">
                      <argon-input id="email" v-model="model.email" type="text" placeholder="Telefone" name="email"
                        size="lg" />
                    </div>
                    <div class="mb-3">
                      <argon-input id="password" v-model="model.password" type="password" placeholder="Senha"
                        name="password" size="lg" />
                    </div>
                    <argon-switch id="rememberMe" v-model="model.rememberMe" name="remember-me">Manter
                      conectado</argon-switch>

                    <div class="text-center">
                      <argon-button class="mt-4" variant="gradient" color="success" fullWidth
                        size="lg">Entrar</argon-button>
                    </div>

                    <div v-if="error" class="mt-3 text-danger">{{ error }}</div>
                  </form>
                </div>
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Não tem uma conta?
                    <a href="javascript:;" class="text-success text-gradient font-weight-bold">Cadastre-se</a>
                  </p>
                </div> -->
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg');
                  background-size: cover;
                ">
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4 class="mt-5 text-white font-weight-bolder position-relative">
                  Scudo - Tecnologia e Sistemas
                </h4>
                <p class="text-white position-relative">
                  Com tecnologia, seu negócio ganha eficiência, cresce mais rápido e inova constantemente!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
