<script>
import FullCalendar from "@fullcalendar/vue3";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import axios from "axios";
import VisualizarResumoAtendimentoForm from "../atendimentos/VisualizarResumoAtendimentoForm.vue";
import CadastrarAtendimentoWizardForm from "../atendimentos/CadastrarAtendimentoWizardForm.vue";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import { Modal } from 'bootstrap';


export default {
  components: {
    CadastrarAtendimentoWizardForm,
    VisualizarResumoAtendimentoForm,
    FullCalendar
  },
  props: {
    id: { type: String, default: "widget-calendar" },
    title: { type: String, default: "" },
    day: { type: String, default: "" },
    year: { type: String, default: "" },
    initialView: { type: String, default: "timeGridWeek" },
    initialDate: {
      type: String,
      default: () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${year}-${month}-${day}`;
      }
    }
  },
  data() {
    return {
      data: JSON.parse(localStorage.getItem('userData')),
      selectedEvent: { title: "", start: "", end: "" },
      isNewEvent: true,
      selectedColaborador: null,
      loading: false,
      colaboradorOptions: [],
      calendarOptions: {
        plugins: [timeGridPlugin, interactionPlugin],
        initialView: this.isSmallScreen() ? "timeGridDay" : "timeGridWeek",
        headerToolbar: {
          start: "title",
          right: "today prev,next",
          left: "timeGridDay timeGridWeek",
        },
        slotDuration: "00:10:00",
        allDaySlot: false,
        slotMinTime: "07:00:00",
        slotMaxTime: "21:10:00",
        initialDate: this.initialDate,
        selectable: this.selectable,
        editable: this.editable,
        slotLabelInterval: "00:15",
        scrollTime: "09:00:00",
        locale: "pt-br",
        locales: [ptBrLocale],
        events: this.events,
        contentHeight: 550,
        dateClick: (info) => this.handleDateClick(info),
        eventClick: (info) => this.handleEventClick(info),
        datesSet: (info) => this.handleDatesSet(info),
      },
      dateSelected: null,
      timeSelected: null,
      modalVisualizarAtendimento: null,
      modalVisualizarAtendimentoOpened: false,
      modalCadastrarAtendimento: null,
      modalCadastrarAtendimentoOpened: false
    };
  },
  methods: {
    handleDateClick(info) {
      this.isNewEvent = true;
      this.selectedEvent = { title: "", start: info.dateStr, end: info.dateStr };
      this.modalCadastrarAtendimento.show();
      this.modalCadastrarAtendimentoOpened = true;
      this.dateSelected = new Date(info.dateStr).toLocaleDateString('pt-BR');
      this.timeSelected = new Date(info.dateStr).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
    },
    handleEventClick(info) {
      this.isNewEvent = false;
      this.selectedEvent = {
        id: info.event.id,
        title: info.event.title || "",
        start: info.event.startStr || "",
        end: info.event.endStr || info.event.startStr || "",
      };
      this.visualizar(this.selectedEvent.id)
    },
    abrirModalAgendamento() {
      this.dateSelected = null;
      this.timeSelected = null;
      this.modalCadastrarAtendimento.show();
      this.modalCadastrarAtendimentoOpened = true;
    },
    isSmallScreen() {
      return window.innerWidth <= 768;
    },
    async fetchAgendaColaborador(data) {
      try {
        let url = "";

        if (this.selectedColaborador === null || this.selectedColaborador === undefined) {
          url = `${process.env.VUE_APP_API_ENDPOINT}/agenda/recuperar_agenda_colaborador?empresaId=${this.data.idEmpresa}&data=${data}`;
        } else {
          url = `${process.env.VUE_APP_API_ENDPOINT}/agenda/recuperar_agenda_colaborador?colaboradorId=${this.selectedColaborador}&data=${data}`;
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.data.token}`
          }
        });
        const servicos = response.data.servicos;

        const events = servicos.map((servico) => {
          const dateParts = servico.data.split("/");
          const dateFormatted = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

          return {
            id: servico.id,
            title: `${servico.nomeServico} - Cliente: ${servico.cliente.nome}`,
            start: `${dateFormatted}T${servico.horaInicio}`,
            end: `${dateFormatted}T${servico.horaFimPrevisto}`,
            className: "bg-gradient-info",
          };
        });

        this.calendarOptions.events = events;
      } catch (error) {
        console.error("Erro ao buscar a agenda do colaborador:", error);
      }
    },
    fetchColaboradores(searchQuery) {
      this.loading = true;

      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaboradores/empresa`, {
        params: {
          idEmpresa: this.data.idEmpresa,
          nome: searchQuery
        },
        headers: {
          Authorization: `Bearer ${this.data.token}`
        }
      }).then(response => {
        this.colaboradorOptions = response.data.colaboradores.map(colaborador => ({
          value: colaborador.id,
          label: `${colaborador.nome} | Contato: ${colaborador.telefone || ''}`
        }));
        this.loading = false;
      }).catch(error => {
        console.error('Erro ao buscar colaboradores:', error);
        this.colaboradorOptions = [];
        this.loading = false;
      });
    },
    getSelectedMonth() {
      let calendarApi = this.$refs.cc.getApi();
      const data = calendarApi.getDate().toISOString();
      const month = calendarApi.getDate().getMonth() + 1;
      const year = calendarApi.getDate().getFullYear();
      const dataFormatada = new Date(data).toLocaleDateString('pt-BR');
      return { month, year, data, dataFormatada };
    },
    handleDatesSet() {
      this.fetchAgendaColaborador(this.getSelectedMonth().dataFormatada)
    },
    visualizar(atendimentoId) {
      if (atendimentoId) {
        this.selectedEvent.id = atendimentoId;
        this.modalVisualizarAtendimento.show();
        this.modalVisualizarAtendimentoOpened = true;
      }
    },
    closeModal() {
      this.modalVisualizarAtendimento.hide();
      this.modalVisualizarAtendimentoOpened = false;
      this.modalCadastrarAtendimento.hide();
      this.modalCadastrarAtendimentoOpened = false;
      this.handleDatesSet();
    },
    handleFiltersChange() {
      this.handleDatesSet();
    },
  },
  mounted() {
    this.handleDatesSet();
    this.modalCadastrarAtendimento = new Modal(this.$refs.modalCadastrarAtendimento);
    this.modalVisualizarAtendimento = new Modal(this.$refs.modalVisualizarAtendimento);
  },
};
</script>




<template>
  <div>
    <div class="card widget-calendar">
      <div class="p-3 pb-0 card-header">
        <div class="row mt-0">
          <div class="col-12 col-md-10 mt-3 mt-md-0">
            <el-select-v2 v-model="selectedColaborador" loading-text="Buscando" no-data-text="Não encontrado"
              no-match-text="Não encontrado!" clearable filterable remote :remote-method="fetchColaboradores"
              @change="handleFiltersChange" :options="colaboradorOptions" :loading="loading"
              placeholder="Digite o nome do Colaborador para buscar">
            </el-select-v2>
          </div>
          <div class="col-12 col-md-2 mt-3 mt-md-0">
            <button type="button" class="btn btn-success btn-sm p-3 w-100 w-md-auto" style="float:right; font-size: 0.7em"
              @click="abrirModalAgendamento">
              Agendar Atendimento
            </button>
          </div>
        </div>
        <h6 class="mb-0">{{ title }}</h6>
        <div class="d-flex">
          <div class="mb-0 text-sm p font-weight-bold widget-calendar-day">
            {{ day }}
          </div>
          <div class="mb-1 text-sm p font-weight-bold widget-calendar-year">
            {{ year }}
          </div>
        </div>
      </div>
      <div class="p-3 card-body">
        <FullCalendar ref="cc" :options="calendarOptions" />
      </div>
    </div>

    <div class="modal fade" ref="modalVisualizarAtendimento" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-xl mb-6">
        <div class="modal-content">
          <div class="modal-header text-xs text-secondary mb-0">
            <h5 class="modal-title" style="margin-left: 10px;">Visualizar Atendimento</h5>
            <button type="button" class="btn-close" style="color: black; font-size: 0.9rem"
              @click="closeModal">X</button>
          </div>
          <div class="modal-body">
            <VisualizarResumoAtendimentoForm :atendimentoId="+selectedEvent.id" :closeModal="closeModal"
              :modalOpened="modalVisualizarAtendimentoOpened" />
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" ref="modalCadastrarAtendimento" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-xl mb-6">
        <div class="modal-content">
          <div class="modal-header text-xs text-secondary mb-0">
            <h5 class="modal-title" style="margin-left: 10px;">Cadastrar Agendamento</h5>
            <button type="button" class="btn-close" style="color: black; font-size: 0.9rem"
              @click="closeModal">X</button>
          </div>
          <div class="modal-body p-4">
            <CadastrarAtendimentoWizardForm :closeModal="closeModal" :modalOpened="modalCadastrarAtendimentoOpened" :dateSelected="dateSelected" :timeSelected="timeSelected"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

::v-deep(.el-select__wrapper) {
  padding: 14px 12px !important;
}


@media (max-width: 414px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .nome-width {
    max-width: 0px;
  }

  ::v-deep(.el-select__wrapper) {
    padding: 15px 12px !important;
  }

}
</style>