<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-10 pt-2 pb-10 m-3 border-radius-lg"
      style="background-color: rgb(236 236 236);
        /* background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg&quot;); */
        background-position: top;">
      <span class="mask bg-gradient-dark"></span>
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-lg-12 text-end">
            <button
              type="button"
              class="btn btn-responsive btn-success mt-3"
              @click="toggleWizard">
                {{ isMeusAgendamentos ? 'Novo Agendamento' : 'Meus Agendamentos' }}
            </button>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <p class="text-lead text-white mb-1 mt-md-3 mt-5">
              Seja bem vindo(a) a:
            </p>
            <h1 class="text-white">{{ empresa?.nome }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-8 col-lg-8 col-md-8 mx-auto mb-5">
          <div class="card z-index-0">
            <form-wizard v-if="!isMeusAgendamentos" step-size="xs" class="mt-3" color="#2dce89" 
            back-button-text="Voltar"
            next-button-text="Go next!"
            finish-button-text="We're there" ref="formWiz">
            <hr class="my-3">
              <tab-content title="Selecione o Serviço">
                
                  <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Descrição:</th>
                          <th class="text-center text-secondary opacity-7"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(servico, index) in servicos" :key="index" @click="defineServico(servico)"
                          style="cursor: pointer;">
                          <td class="nome-width">
                            <div class="d-flex px-3 py-1">
                              <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">{{ servico.nomeServico }}</h6>
                                <h7 class="mb-0 pt-2 text-sm text-secondary text-wrap">{{ servico?.descricaoServico || '' }}</h7>
                              </div>
                            </div>
                          </td>
                          <td style="text-align: right;">
                            <button type="button" class="btn btn-responsive btn-success mt-3" @click.stop="defineServico(servico)">
                              <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <td colspan="4" class="text-right">
                            
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                 
                </div>
              </tab-content>
              <tab-content title="Selecione o Profissional">
                <div class="">
                  <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Colaboradores:</th>
                          <th class="text-center text-secondary opacity-7"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(colaborador, index) in colaboradores" :key="index" @click="defineColaborador(colaborador)"
                          style="cursor: pointer;">
                          <td class="nome-width">
                            <div class="d-flex px-1 py-1">
                              <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-md">{{ colaborador.nome }}</h6>
                                <h6 class="mb-0 text-sm"><i class="fa fa-money" aria-hidden="true"></i> R$ {{ colaborador.servico.preco.toFixed(2) }}</h6>
                                <h6 class="mb-0 text-sm">
                                  <i class="fa fa-clock-o" aria-hidden="true"></i> {{ colaborador.servico.duracao }} min</h6>
                              </div>
                            </div>
                          </td>
                          <td style="text-align: right;">
                            <button type="button" class="btn btn-responsive btn-success mt-3" @click.stop="defineColaborador(colaborador)">
                              <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <td colspan="4" class="text-right">
                            
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </tab-content>
              <tab-content title="Selecione o Dia e Hora">
                <div class="calendar-container">
                  <div class="month-header row">
                    <div class="col-12 text-center">
                      <h6 class="mb-0 text-md">{{ currentMonth }}</h6>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="week-days">
                        <button class="btn btn-success" style="padding: 12px !important; margin-top: 18px !important; margin-right: 3px;" @click="previousWeek">❮</button>

                        <div
                          v-for="(day, index) in daysOfWeek"
                          :key="index"
                          :class="['day-box', {active: selectedDate === day.date, disabled: isBeforeToday(day.date) && selectedDate != day.date }]"
                          @click="!isBeforeToday(day.date) && selectDate(day.date)"
                        >
                          <div class="day-name">{{ day.name }}</div>
                          <div class="day-number">{{ day.number }}</div>
                        </div>
                        <button class="btn btn-success" style="padding: 12px !important; margin-top: 18px !important; margin-left: 3px;" @click="nextWeek">❯</button>

                      </div>
                    </div>
                  </div>
                </div>
                <span style="margin-right: 0.35rem; margin-left: 0.35rem;" v-for="(horario, index) in horarios" :key="index">
                  <button  class="btn btn-responsive btn-success" @click="defineHorario(horario)" type="button">
                    {{horario.hora}}
                  </button>
                </span>
              </tab-content>
              <tab-content title="Finalizar Agendamento">
                <div class="card-body">
                  <h6 class="mb-0 text-lg text-center">Resumo:</h6>
                  <hr/>
                  <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-md">{{ colaboradorSelecionado?.nome || '' }}</h6>
                      <h6 class="mb-0 pt-2 text-sm">{{ colaboradorSelecionado?.servico?.nome || '' }}</h6>
                      <h7 class="mb-0 pt-2 text-sm text-secondary text-wrap">{{ servicoSelecionado?.descricaoServico || '' }}</h7>
                      <h6 class="mb-0  pt-2 text-sm">
                        <i class="fa fa-money" aria-hidden="true"></i>
                        R$ {{ colaboradorSelecionado?.servico?.preco?.toFixed(2) || '0.00' }}
                      </h6>
                      <h6 class="mb-0 pt-2 text-sm">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                        {{ colaboradorSelecionado?.servico?.duracao || '0' }} min
                      </h6>
                      <h6 class="mb-0 pt-2 text-sm">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                        {{ horarioSelecionado?.data || '' }} às {{ horarioSelecionado?.hora || '' }}h
                      </h6>
                    </div>
                  <hr/>
                  <h6 class="mb-0 text-lg text-center">Dados Pessoais:</h6>
                  <form @submit.prevent="salvarAgendamento">
                    <div class="mb-3">
                      <label for="nome">Nome: <i class="fa fa-whatsapp" aria-hidden="true"></i></label>
                      <input type="text" name="nome" id="nome" minlength="3" required class="form-control" placeholder="Nome e Sobrenome" v-model="cliente.nome">
                    </div>
                    <div class="mb-3">
                      <label for="telefoneCliente">Telefone <i class="fa fa-whatsapp" aria-hidden="true"></i></label>
                      <input type="text" name="phone" id="telefoneCliente" required class="form-control" minlength="16" placeholder="(00) 0 0000-0000" v-model="cliente.telefone" v-mask-phone.br>
                    </div>
                    <div class="text-center mt-5">
                      <button type="submit" class="btn btn-lg btn-success" 
                        variant="success">Finalizar Agendamento</button>
                    </div>
                  </form>
                  <div class="pt-1" v-if="showAlert">
                    <argon-alert color="success" icon="ni ni-like-2 ni-lg">
                      <strong class="size-font-alert">{{ infoMessage }}</strong>
                    </argon-alert>
                  </div>

                  <div class="pt-1" v-if="showAlertError">
                    <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
                      <strong class="size-font-alert">{{ errorMessage }}</strong>
                    </argon-alert>
                  </div>
                </div>
              </tab-content>
            </form-wizard>

            <form-wizard v-if="isMeusAgendamentos" step-size="xs" class="mt-3" color="#2dce89" 
            back-button-text="Voltar"
            next-button-text="Go next!"
            finish-button-text="We're there" ref="formWiz">
            <hr class="my-3">
              <tab-content title="Informe o telefone">
                <form @submit.prevent="buscaAtendimentos">
                  <div class="row">
                    <div class="col-12 col-lg-10">
                      <label for="telefoneCliente">Número utilizado no agendamento: <i class="fa fa-whatsapp" aria-hidden="true"></i></label>
                      <input type="text" name="phone" id="telefoneCliente" required class="form-control" minlength="16" placeholder="(00) 0 0000-0000" v-model="cliente.telefone" v-mask-phone.br>
                    </div>
                    <div class="col-12 col-lg-2 ">
                      <label for="telefoneClientea"></label>

                      <button type="submit" class="btn btn-success text-center form-control mt-1">
                        Buscar
                      </button>
                    </div>
                  </div>
                </form>
                
              </tab-content>
              
              <tab-content title="Meus agendamentos:">
                <div class="card-body">
                  <h6 class="mb-0 text-lg text-center">Agendamentos:</h6>
                  <hr/>

                  <div v-for="(servico, index) in servicosCliente" :key="index" class="d-flex flex-column justify-content-center">
                    <div class="row">
                      <div class="col-12 col-md-9">
                        <h6 class="mb-0 text-md">{{ servico?.colaborador?.nome || '' }}</h6>
                        <h6 class="mb-0 pt-2 text-sm">{{ servico?.nomeServico || '' }}</h6>
                        <h7 class="mb-0 pt-2 text-sm text-secondary text-wrap">{{ servico?.descricaoServico || '' }}</h7>
                        <h6 class="mb-0  pt-2 text-sm">
                          <i class="fa fa-money" aria-hidden="true"></i>
                          R$ {{ servico?.valorFinal?.toFixed(2) || '0.00' }}
                        </h6>
                        <h6 class="mb-0 pt-2 text-sm">
                          <i class="fa fa-clock-o" aria-hidden="true"></i>
                          {{ servico?.duracao || '0' }} min
                        </h6>
                        <h6 class="mb-0 pt-2 text-sm">
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                          {{ servico?.data || '' }} - {{ servico?.horaInicio || '' }} às {{ servico?.horaFimPrevisto || '' }}
                        </h6>
                        <h6 class="mb-0 pt-2 text-sm">
                          Situação:
                          {{ servico?.situacao || '' }}
                          <i class="fa fa-check" aria-hidden="true"></i>
                        </h6>
                      </div>
                      <div class="col-12 col-md-3 mt-2 mt-md-0 d-flex flex-column justify-content-center">
                        <button
                          type="button"
                          class="btn btn-danger" :disabled="servico?.situacao !== 'Agendado'"
                          @click="excluirAgendamento(servico?.id)">Cancelar <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                    <hr/>
                  </div>  
                </div>
              </tab-content>
            </form-wizard>
            
            <div class="pt-4" v-if="showAlert && isMeusAgendamentos">
              <argon-alert color="success" icon="ni ni-like-2 ni-lg">
              <strong class="size-font-alert">{{ infoMessage }}</strong>
              </argon-alert>
            </div>

            <div class="pt-1" v-if="showAlertError && isMeusAgendamentos">
              <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
              <strong class="size-font-alert">{{ errorMessage }}</strong>
              </argon-alert>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-8 col-lg-8 col-md-8 mx-auto mb-5">
          <div class="card z-index-0">
            
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { useStore } from "vuex";
import axios from 'axios';
import {FormWizard, TabContent} from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import { format, addDays, startOfWeek } from "date-fns";
import { ptBR } from "date-fns/locale";
import ArgonAlert from "@/components/ArgonAlert.vue";
import Swal from 'sweetalert2';

export default {
  name: "BemVindo",
  components: {
    FormWizard,
    TabContent,
    ArgonAlert
    // Navbar,
  },
  data() {
    return {
      body: document.getElementsByTagName("body")[0],
      user: JSON.parse(localStorage.getItem('userData')) || {},
      hashEmpresa: null,
      telefoneEmpresa: null,
      servicos: [],
      servicoSelecionado: null,
      servicosCliente: [],
      colaboradores: [],
      horarios: [],
      empresa: null,
      colaboradorSelecionado: null,
      horarioSelecionado: null,
      cliente: {
        telefone: null,
        nome: null
      },
      daysOfWeek: [],
      currentDate: new Date(),
      selectedDate: null,
      currentMonth: format(new Date(), "MMMM yyyy",  { locale: ptBR }).replace(/^./, (str) => str.toUpperCase()),
      timeSlots: [],
      showAlert: false,
      showAlertError: false,
      modalVisualizarAtendimentos: null,
      modalVisualizarAtendimentosOpened: false,
      isMeusAgendamentos: false,
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      })
    };
  },
  mounted() {
    const store = useStore();
    store.state.hideConfigButton = true;
    store.state.showNavbar = false;
    store.state.showSidenav = false;
    store.state.showFooter = false;
    this.body.classList.remove("bg-gray-100");
    this.telefone = this.$route.params.telefone;
    this.fetchDadosEmpresa(this.telefone);
    // this.modalVisualizarAtendimentos = new Modal(this.$refs.modalVisualizarAtendimentos);
  },
  beforeUnmount() {
    const store = useStore();
    store.state.hideConfigButton = false;
    store.state.showNavbar = true;
    store.state.showSidenav = true;
    store.state.showFooter = true;
    this.body.classList.add("bg-gray-100");
  },
  methods: {
    goFoward() {
      this.$refs.formWiz.nextTab()
    },
    reset() {
      this.$refs.formWiz.reset()
    },
    getHashFromUrl() {
      const pathSegments = window.location.pathname.split('/');
      return pathSegments[pathSegments.length - 1];
    },
    exibeAlerta(tipo, texto){
      this.toast.fire({
        icon: tipo,
        title: texto
      });
    },
    async fetchServicos(hashEmpresa) {
      console.log(hashEmpresa)
      try {
        const url = `${process.env.VUE_APP_API_ENDPOINT}/servicos/empresa/externo?hash=${hashEmpresa}`;
        const response = await axios.get(url);

        this.servicos = response.data.servicos;
      } catch (error) {
        this.servicos = [];
        this.exibeAlerta('error', "Serviços não encontrados!");
        console.error('Erro ao buscar serviços:', error);
        this.$router.push({ name: 'NotFound' });
      }
    },
    async fetchColaboradoresRealizamServicoSelecionado(hashEmpresa, hashServico) {
      if(this.servicoSelecionado != null){
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaborador_servicos/servico`, {
            params: {
              hashEmpresa: hashEmpresa,
              hashServico: hashServico
            },  
          });
          this.colaboradores = response.data.colaboradores;
        } catch (error) {
          this.colaboradores = []
          this.exibeAlerta('warning', "Colaborador(es) não encontrado(s) para esse serviço!")
          console.error('Erro ao buscar colaboradores:', error);
        }
      }
    },
    async fetchDadosEmpresa(telefone) {
      if(telefone != null){
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/empresas/buscar`, {
            params: {
              telefone: telefone
            },
          });
          this.empresa = response.data;
          this.hashEmpresa = this.empresa?.hash;
          this.fetchServicos(this.hashEmpresa);

        } catch (error) {
          this.horarios = []
          console.error('Erro ao buscar dados da empresa:', error);
          this.$router.push({ name: 'NotFound' });
        }
      }
    },
    async fetchHorariosDisponiveisColaboradores(hashColaboradorServico, data) {
      if(this.servicoSelecionado != null){
        console.log(hashColaboradorServico)
        console.log(data)
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/agenda/recuperar_horarios_disponiveis`, {
            params: {
              hashColaboradorServico,
              data
            },
          });
          this.horarios = response.data.horarios;
          if(this.horarios.length == 0){
            this.exibeAlerta('warning', "Nenhum horário disponível!")
          }
        } catch (error) {
          this.exibeAlerta('warning', "Nenhum horário disponível!")
          this.horarios = []
          console.error('Erro ao buscar horários:', error);
        }
      }
    },
    async excluirAgendamento(servicoId) {
      axios.delete(`${process.env.VUE_APP_API_ENDPOINT}/agenda/externo/servico_agendado_cliente`, {
        params: {
          servicoId: servicoId
        },
      }).then(response => {
          if (response.status === 204) {
            // this.showInfoMessage("Agendamento cancelado com sucesso!");
            this.exibeAlerta('success', "Agendamento cancelado com sucesso!")
          }
          this.reset()
        })
        .catch(error => {
          console.error("Erro ao deletar o atendimento:", error);
          this.showErrorMessage("Erro ao excluir serviço agendado!");
        });
    },
    toggleWizard() {
      this.isMeusAgendamentos = !this.isMeusAgendamentos;
    },
    defineServico(servico){
      this.servicoSelecionado = servico;
      this.fetchColaboradoresRealizamServicoSelecionado(this.hashEmpresa, this.servicoSelecionado.hash);
      this.goFoward();
      console.log(this.servicoSelecionado);
    },
    async buscaAtendimentos(){
      console.log(this.cliente.telefone)
      const telefoneLimpo = this.cliente.telefone.replace(/\D/g, "");

      if (telefoneLimpo.length < 11) {
        this.telefoneInvalido = true;
        this.exibeAlerta('warning', "Insira um telefone válido!");
      } else {
        this.telefoneInvalido = false;
      }

      try {

        const url = `${process.env.VUE_APP_API_ENDPOINT}/agenda/externo/recuperar_agendamentos_cliente?telefone=${this.cliente.telefone}&empresaHash=${this.hashEmpresa}`;
        
        const response = await axios.get(url);

        this.servicosCliente = response.data.servicos;
      } catch (error) {
        this.servicosCliente = [];
        this.exibeAlerta('error', "Nenhum agendamento encontrado!");
        console.error('Erro ao buscar servicos do cliente:', error);
      }

      this.goFoward();

      
    },
    generateWeekDays() {
      const start = startOfWeek(this.currentDate, { weekStartsOn: 1 });
      this.daysOfWeek = Array.from({ length: 7 }).map((_, i) => {
        const day = addDays(start, i);
        
        return {
          name: format(day, "EEEEEE", { locale: ptBR }).toUpperCase(),
          number: format(day, "dd"), 
          date: format(day, "dd-MM-yyyy"), 
        };
      });
      this.currentMonth = format(new Date(), "MMMM yyyy",  { locale: ptBR }).replace(/^./, (str) => str.toUpperCase());
    },
    isBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); 
      
      const [day, month, year] = date.split("-");
      const formattedDate = new Date(year, month - 1, day); 
      formattedDate.setHours(0, 0, 0, 0); 
      
      console.log(formattedDate < today); 
      console.log(formattedDate);
      console.log(today);

      return formattedDate < today;
    },
    defineColaborador(colaborador){
      this.colaboradorSelecionado = colaborador;
      this.goFoward();
      this.generateWeekDays();
      const dataAtual = format(new Date(), "dd-MM-yyyy", { locale: ptBR }); 
      this.selectDate(dataAtual);
    },
    async fetchTimeSlots(date) {
      console.log(date)
      this.fetchHorariosDisponiveisColaboradores(this.colaboradorSelecionado.servico.hash, date.replaceAll('-', '/'));
    },
    formatDate(dateStr) {
      const [year, month, day] = dateStr.split('/');
      return `${day}/${month}/${year}`;
    },
    selectDate(date) {
      console.log(date)
      this.selectedDate = date;
      this.horarios = [];
      this.fetchTimeSlots(date);
    },
    previousWeek() {
      this.currentDate = addDays(this.currentDate, -7);
      this.generateWeekDays();
      this.currentMonth = format(this.currentDate, "MMMM yyyy",  { locale: ptBR }).replace(/^./, (str) => str.toUpperCase())
    },
    nextWeek() {
      this.currentDate = addDays(this.currentDate, 7);
      this.generateWeekDays();
      this.currentMonth = format(this.currentDate, "MMMM yyyy",  { locale: ptBR }).replace(/^./, (str) => str.toUpperCase())
    },
    defineHorario(horario){
      console.log(this.colaboradorSelecionado)
      this.horarioSelecionado = horario;
      this.goFoward();
      console.log(this.horarioSelecionado);
    },
    showInfoMessage(message, showAlert = true, deveResetar = true) {
      this.infoMessage = message;
      this.showAlert = showAlert;
      if (showAlert) {
        setTimeout(() => {
          this.showAlert = false;
          if(deveResetar){
            this.reset();
          }
        }, 2000);
      }
    },
    showErrorMessage(message, showAlert = true, deveResetar = true) {
      this.errorMessage = message;
      this.showAlertError = showAlert;
      if (showAlert) {
        setTimeout(() => {
          this.showAlertError = false;
          if(deveResetar){
            this.reset();
          }
        }, 2000);
      }
    },
    salvarAgendamento() {
      const payload = {
        hashEmpresa: this.hashEmpresa,
        hashColaboradorServico: this.colaboradorSelecionado.servico.hash,
        nomeCliente: this.cliente.nome,
        telefoneCliente: this.cliente.telefone,
        data: this.horarioSelecionado.data,
        hora: this.horarioSelecionado.hora,
      }

      const telefoneLimpo = this.cliente.telefone.replace(/\D/g, "");

      if (telefoneLimpo.length < 11) {
        this.telefoneInvalido = true;
        this.showErrorMessage("Insira um telefone válido", true, false);
        return
      } else {
        this.telefoneInvalido = false;
      }

      if (this.cliente.nome.length < 3) {
        this.showErrorMessage("Nome inválido", true, false);
        return
      }


      axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agenda/externo/salvar_servico_agenda_colaborador`, payload, {
        }).then(response => {
          if (response.status === 201) {
            this.exibeAlerta('success', "Agendamento salvo com sucesso!");
            setTimeout(() => {
              this.reset();
              this.isMeusAgendamentos = true;
              this.buscaAtendimentos();
            }, 2000);
          }   
        }).catch(error => {
          console.log(error);
          this.showErrorMessage("Erro ao salvar agendamento!");
        });
      }
    },
    visualizar(clienteTelefone) {
      if (clienteTelefone) {
        this.modalVisualizarAtendimentos.show();
        this.modalVisualizarAtendimentosOpened = true;
      }
    },
    closeModal() {
      this.modalVisualizarAtendimentos.hide();
      this.modalVisualizarAtendimentosOpened = false;
    },
  }

</script>
<style scoped>
::v-deep(.wizard-footer-right .wizard-btn) {
  display: none !important;
}

::v-deep(.vue-form-wizard .wizard-nav-pills li, .vue-form-wizard .wizard-nav-pills a) {
  cursor: not-allowed;
  pointer-events: none;
}

::v-deep(.bg-gradient-dark){
  background-image: linear-gradient(310deg, #0d3c35 0%, #10c584 100%) !important;
}

.calendar-container {
  font-family: Arial, sans-serif;
  color: #fff;
  border-radius: 10px;
  /* width: 400px; */
  margin: auto;
}

.month-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-bottom: 10px;
}

.week-days {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.day-box {
  text-align: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background: #333;
  transition: background 0.3s;
}

.day-box.active {
  background: #2dce89;
  color: #000;
}

.day-box.disabled {
  pointer-events: none;
  background: #484949;
  opacity: 0.5;
  cursor: not-allowed;
}

.day-name {
  font-size: 12px;
  margin-bottom: 5px;
}

.day-number {
  font-size: 20px;
  font-weight: bold;
}

.time-slots {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.time-box {
  background: #333;
  border-radius: 15px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background 0.3s;
}

.time-box:hover {
  background: #2dce89;
  color: #000;
}



@media (max-width: 435px) {
  .day-box {
    text-align: center;
    cursor: pointer;
    padding: 2px;
    border-radius: 5px;
    background: #333;
    transition: background 0.3s;
  }
}

</style>

