<template>
  <div class="card">
    <div class="card-header pb-0 border-0">
      <div class="row">
        <div class="col-12 col-md-4">
          <h3>Horário de Trabalho</h3>
        </div>
      </div>
    </div>
    <hr class="my-1 " />

    <div class="card-body px-0 pt-0 pb-0">
      <div class="table-responsive p-0">
        <form @submit.prevent="cadastrarHorarioTrabalho">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase font-weight-bolder">TURNO</th>
                <th class="text-uppercase font-weight-bolder">SEG</th>
                <th class="text-uppercase font-weight-bolder">TER</th>
                <th class="text-uppercase font-weight-bolder">QUA</th>
                <th class="text-uppercase font-weight-bolder">QUI</th>
                <th class="text-uppercase font-weight-bolder">SEX</th>
                <th class="text-uppercase font-weight-bolder">SAB</th>
                <th class="text-uppercase font-weight-bolder">DOM</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="turno in turnos" :key="turno.turno">
                <td>
                  <h6 class="text-xs text-secondary mb-0">{{ turno.label }}</h6>
                </td>
                <td v-for="(dia, index) in dias" :key="index">
                  <input
                    type="time"
                    class="form-control"
                    placeholder="hh:mm"
                    v-model="horarios[dia][turno.campo]"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row mt-4">
            <div class="col-12 col-md-8">
              <div v-if="showAlert">
                <argon-alert color="success" icon="ni ni-like-2 ni-lg">
                  <strong class="size-font-alert">{{ infoMessage }}</strong>
                </argon-alert>
              </div>

              <div v-if="showAlertError">
                <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
                  <strong class="size-font-alert">{{ errorMessage }}</strong>
                </argon-alert>
              </div>
            </div>
            
            <div class="col-12 col-md-4 d-flex justify-content-end">
              <button type="submit" class="btn btn-md btn-success">SALVAR/ATUALIZAR HORÁRIOS</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ArgonAlert from "@/components/ArgonAlert.vue";

export default {
  name: "listar-colaboradores-empresa",
  components: {
    ArgonAlert,
  },
  props: {
    idColaborador: {
      type: Number,
      required: true,
    },
    modalOpened: {
      type: Boolean,
    },
  },
  data() {
    return {
      dias: ["seg", "ter", "qua", "qui", "sex", "sab", "dom"],
      turnos: [
        { label: "Manhã - Início", campo: "manhaInicio" },
        { label: "Manhã - Fim", campo: "manhaFim" },
        { label: "Tarde - Início", campo: "tardeInicio" },
        { label: "Tarde - Fim", campo: "tardeFim" },
        { label: "Noite - Início", campo: "noiteInicio" },
        { label: "Noite - Fim", campo: "noiteFim" },
      ],
      horarios: {
        seg: {},
        ter: {},
        qua: {},
        qui: {},
        sex: {},
        sab: {},
        dom: {},
      },
      user: JSON.parse(localStorage.getItem('userData')) || {},
      showAlert: false,
      showAlertError: false,
      infoMessage: '',
      errorMessage: ''
    };
  },
  methods: {
    async fetchHorarioTrabalhoColaborador() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/agenda/horario_trabalho_colaborador`,
          {
            params: {
              colaboradorId: this.idColaborador,
            },
            headers: {
              Authorization: `Bearer ${this.user.token}`,
            },
          }
        );

        const horariosTrabalho = response.data.horariosTrabalho || [];

        this.horarios = this.dias.reduce((acc, dia, index) => {
          const horarioDia = horariosTrabalho.find(h => h.diaSemana === index + 1);
          acc[dia] = horarioDia
            ? {
                manhaInicio: horarioDia.horaInicioManha || null,
                manhaFim: horarioDia.horaFimManha || null,
                tardeInicio: horarioDia.horaInicioTarde || null,
                tardeFim: horarioDia.horaFimTarde || null,
                noiteInicio: horarioDia.horaInicioNoite || null,
                noiteFim: horarioDia.horaFimNoite || null,
              }
            : {};
          return acc;
        }, {});
      } catch (error) {
        console.error("Erro ao buscar os horários de trabalho:", error);
        alert("Erro ao carregar os horários de trabalho. Tente novamente.");
      }
    },

    async cadastrarHorarioTrabalho() {
      const payload = {
        idColaborador: this.idColaborador,
        horariosTrabalho: this.dias.map((dia, index) => ({
          diaSemana: index + 1,
          horarioInicioManha: this.horarios[dia].manhaInicio || null,
          horarioFimManha: this.horarios[dia].manhaFim || null,
          horarioInicioTarde: this.horarios[dia].tardeInicio || null,
          horarioFimTarde: this.horarios[dia].tardeFim || null,
          horarioInicioNoite: this.horarios[dia].noiteInicio || null,
          horarioFimNoite: this.horarios[dia].noiteFim || null,
        })),
      };

      axios.post(`${process.env.VUE_APP_API_ENDPOINT}/agenda/horario_trabalho_colaborador`, payload, {
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
        if (response.status === 201) {
          this.infoMessage = "Horários de trabalho salvos com sucesso!";
          this.showAlert = true;
          setTimeout(() => {
            this.showAlert = false;
          }, 2000);
        }
      }).catch(error => {
        console.log(error)
        this.errorMessage = "Erro ao salvar/atualizar horários!";
        this.showAlertError = true;
      });
    }
  },
  watch: {
    modalOpened(newValue) {
      if (newValue) {
        this.fetchHorarioTrabalhoColaborador();
      }
    },
  },
};
</script>


<style scoped>
.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.card {
  box-shadow: none;
}

h3 {
  font-size: 1rem;
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.0rem;
  }
}
</style>
