<template>
  <form @submit.prevent="cadastrarColaborador">
    <h6 class="heading-small text-muted mb-4">Informações Básicas</h6>

    <div>
      <div class="row">
        <div class="col-12 col-md-12 mt-3 mt-md-0 ">
          <label for="nomeServico">Serviço da Empresa</label>
          <el-select-v2 id="nomeServico" v-model="colaborador_servicos.idServico" loading-text="Buscando"
            no-data-text="Não encontrado" no-match-text="Não encontrado!" clearable filterable remote
            :remote-method="fetchServicosColaborador" :options="serviceOptions"
            :loading="loading" placeholder="Digite o nome do Serviço para buscar">
          </el-select-v2>
        </div>
        
        
      </div>

      <div class="row mt-2">
        <div class="col-6 col-md-4 mt-2 mt-md-0">
          <label for="valor">Valor</label>
          <label for="valor">Valor (R$):</label>
          <money3 class="form-control" v-model="colaborador_servicos.preco" v-bind="config"></money3>
        </div>
        <div class="col-6 col-md-4 mt-2 mt-md-0 ">
          <label for="hora">Duração</label>
          <input type="time" id="hora" class="form-control" v-model="colaborador_servicos.duracao" required/>
        </div>
        <div class="col-12 col-md-4 mt-2 mt-md-0">
          <label for="statusServicoColaborador">Status</label>
          <select v-model="colaborador_servicos.situacao" id="statusServicoColaborador" class="form-control">
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-md-2 text-left">
        <a href="#!" class="btn btn-sm btn-primary btn-responsive" @click="goBack">VOLTAR</a>
      </div>
      <div class="col-10 col-md-10 d-flex justify-content-end">
        <button type="submit" class="btn btn-sm btn-success ml-2 btn-responsive" style="margin-left: 5px;"
          variant="success">SALVAR</button>
      </div>
    </div>
  </form>

  <div class="pt-4" v-if="showAlert">
    <argon-alert color="success" icon="ni ni-like-2 ni-lg">
      <strong class="size-font-alert">{{ infoMessage }}</strong>
    </argon-alert>
  </div>

  <div class="pt-4" v-if="showAlertError">
    <argon-alert color="danger" icon="ni ni-fat-remove ni-lg">
      <strong class="size-font-alert">{{ errorMessage }}</strong>
    </argon-alert>
  </div>
</template>



<script>
import axios from 'axios';
import ArgonAlert from "@/components/ArgonAlert.vue";
import { Money3Component } from 'v-money3'

export default {
  components: {
    ArgonAlert,
    money3: Money3Component
  },
  props: {
    idColaborador: {
      type: Number,
      required: true,
    },
    modalOpened: {
      type: Boolean
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    return {
      user: userData,
      colaborador_servicos: {
        id: 0,
        idColaborador: 0,
        idServico: null,
        preco: 0,
        duracao: 0,
        situacao: 1
      },
      showAlert: false,
      showAlertError: false,
      infoMessage: '',
      errorMessage: '',
      serviceOptions: [],
      loading: false,
      modalCadastroServicoColaborador: null,
      config: {
        masked: false,
        prefix: '',
        suffix: '',
        thousands: ',',
        decimal: '.',
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
    };
  },
  methods: {
    cadastrarColaborador() {
      const [hours, minutes] = this.colaborador_servicos.duracao.split(":").map(Number);
      const durationInMinutes = hours * 60 + minutes;
      const payload = {
        idColaborador: this.idColaborador,
        idServico: this.colaborador_servicos.idServico,
        preco: this.colaborador_servicos.preco,
        duracao: durationInMinutes,
        situacao: this.colaborador_servicos.situacao
      };

      if(this.colaborador_servicos.idServico != null){
        this.showAlertError = false;

        axios.post(`${process.env.VUE_APP_API_ENDPOINT}/colaborador_servicos`, payload, {
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
          }).then(response => {
              if (response.status === 200) {
                this.infoMessage = "Serviço adicionado ao Colaborador com sucesso.";
                this.showAlert = true;
                setTimeout(() => {
                  this.showAlert = false;
                  this.goBack();
                  this.resetForm();
                }, 2000);
              }
            })
            .catch(error => {
              console.error("Falha ao adicionar serviço ao colaborador:", error);
              this.errorMessage = "Falha ao adicionar serviço ao colaborador";
              this.showAlertError = true;
        });
      }else{
        this.errorMessage = "Selecione o serviço para vincular ao colaborador";
        this.showAlertError = true;
      }
    },
    fetchServicosColaborador(searchQuery) {
      
        this.loading = true;

        axios.get(`${process.env.VUE_APP_API_ENDPOINT}/servicos/empresa`, {
          params: {
            idEmpresa: this.user.idEmpresa,
            nome: searchQuery,
            pageNo: 0,
            pageSize: 10
          },
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        })
        .then(response => {
            this.serviceOptions = response.data.servicos.map(servico => ({
              value: servico.id,
              label: `ID: ${servico.id} | Nome: ${servico.nomeServico} `
            }));
            this.loading = false;
          })
          .catch(error => {
            console.error('Erro ao buscar serviços do colaborador:', error);
            this.serviceOptions = [];
            this.loading = false;
          });
      
    },
    resetForm() {
      this.colaborador_servicos = {
        id: 0,
        idColaboradr: 0,
        idServico: null,
        preco: 0,
        duracao: 0,
        situacao: 1
      };
    },
    goBack(){
      this.closeModal()
    }
  },
};
</script>

<style scoped>
.btn-responsive {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .size-font-alert {
    font-size: 0.775rem;
  }
}
</style>
