<template>
    <div class="">
        <div class="table-responsive p-0">
            <h6 class="mb-0 text-md text-center">Selecione o Colaborador:</h6>
            <table class="table align-items-center mb-0">
                <thead>
                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Colaboradores:
                        </th>
                        <th class="text-center text-secondary opacity-7"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(colaborador, index) in colaboradores" :key="index"
                        @click="defineColaborador(colaborador)" style="cursor: pointer;">
                        <td class="nome-width">
                            <div class="d-flex px-1 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-md">{{ colaborador.nome }}</h6>
                                    <h6 class="mb-0 text-sm"><i class="fa fa-money" aria-hidden="true"></i> R$ {{
                        colaborador.servico.preco.toFixed(2) }}</h6>
                                    <h6 class="mb-0 text-sm">
                                        <i class="fa fa-clock-o" aria-hidden="true"></i> {{ colaborador.servico.duracao
                                        }} min
                                    </h6>
                                </div>
                            </div>
                        </td>
                        <td style="text-align: right;">
                            <button type="button" class="btn btn-responsive btn-success mt-3"
                                @click.stop="defineColaborador(colaborador)">
                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td colspan="4" class="text-right">

                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: "BemVindo",
    components: {
    },
    props: {
        hashEmpresa: {
            type: String,
        },
        hashServico: {
            type: String,
        },
        modalOpened: {
            type: Boolean
        }
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('userData')) || {},
            colaboradores: [],
            colaboradorSelecionado: null,
            toast: Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
                }
            })
        };
    },
    methods: {
        async fetchColaboradoresRealizamServicoSelecionado(hashEmpresa, hashServico) {
            if (hashServico != null) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaborador_servicos/servico`, {
                        params: {
                            hashEmpresa: hashEmpresa,
                            hashServico: hashServico
                        },
                    });
                    this.colaboradores = response.data.colaboradores;
                } catch (error) {
                    this.colaboradores = []
                    console.error('Erro ao buscar colaboradores:', error);
                    this.exibeAlerta('warning', "Nenhum colaborador encontrado")
                }
            }
        },
        defineServico(empresaHash, servicoHash) {
            this.fetchColaboradoresRealizamServicoSelecionado(empresaHash, servicoHash);
        },
        defineColaborador(colaboradorServico) {
            this.$emit('colaborador-selecionado', colaboradorServico);
        },
        exibeAlerta(tipo, texto){
            this.toast.fire({
                icon: tipo,
                title: texto
            });
        },
    },
    watch: {
        modalOpened(newValue) {
            if (newValue) {
                this.defineServico(this.hashEmpresa, this.hashServico);
            }
        }
    }
}

</script>