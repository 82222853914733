<template>
    <div class="card-header pb-0 border-0">
        <div class="row">
            <div class="col-12 col-md-12 text-center">
                <h3 class="">Selecione o Cliente:</h3>
            </div>

            <div class="col-12 col-md-9 mt-3 mt-md-3 text-md-center">
                <el-select-v2 v-model="selectedClient" loading-text="Buscando" no-data-text="Não encontrado"
                    no-match-text="Não encontrado!" clearable filterable remote :remote-method="fetchClientesFiltro"
                    @change="defineClienteFiltro(selectedClient)" :options="clientOptions" :loading="loading"
                    placeholder="Digite o nome do Cliente para buscar">
                </el-select-v2>
            </div>

            <div class="col-12 col-md-3 mt-3 mt-md-3">
                <button type="button" class="btn flex-fill btn-success"  style="float:right; width: 100%"
                    @click="cadastrarClientesEmpresa">
                    Novo
                </button>
            </div>
        </div>
    </div>
    <hr class="my-1" />

    <div class="card-body px-0 pt-0 pb-0">
        <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
                <thead>
                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Dados</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-right"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(cliente, index) in clientes" :key="index" @click="defineCliente(cliente)"
                        style="cursor: pointer;">
                        <td class="nome-width">
                            <div class="d-flex px-3 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-sm"><i class="fa fa-user pr-2" aria-hidden="true"></i> {{ cliente.nome }}</h6>
                                    <h6 class="text-xs mt-2"><i class="fa fa-whatsapp" aria-hidden="true"></i> {{ cliente.telefone }}</h6>
                                </div>
                            </div>
                        </td>
                        <td class="align-middle text-sm" style="text-align: right;">
                            <button type="button" class="btn btn-responsive btn-success mt-3"
                                @click.stop="defineCliente(cliente)">
                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td colspan="4" class="text-right">
                            <div class="d-flex justify-content-end mt-3">
                                <argon-pagination>
                                    <argon-pagination-item prev @click="prevPage" :disabled="currentPage === 1" />
                                    <argon-pagination-item v-for="page in pagesToShow" :key="page" :label="String(page)"
                                        :active="page === currentPage" @click="handlePageChange(page)" />
                                    <argon-pagination-item next @click="nextPage"
                                        :disabled="currentPage === totalPages" />
                                </argon-pagination>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>


    <!-- Modal Visualizar Cliente -->
    <div class="modal fade" ref="modalVisualizarCliente" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-xl mb-6">
            <div class="modal-content">
                <div class="modal-header text-xs text-secondary mb-0">
                    <h5 class="modal-title" style="margin-left: 10px;">Visualizar Cliente</h5>
                    <button type="button" class="btn-close" style="color: black; font-size: 0.9rem"
                        @click="closeModal">X</button>
                </div>
                <div class="modal-body p-4">
                    <VisualizarClienteForm :idCliente="idClienteSelecionado" :closeModal="closeModal"
                        :modalOpened="modalOpened" />
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" ref="modalCadastrarCliente" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-md mb-6">
            <div class="modal-content">
                <div class="modal-header text-xs text-secondary mb-0">
                    <h5 class="modal-title" style="margin-left: 10px;">Cadastrar Cliente</h5>
                    <button type="button" class="btn-close" style="color: black; font-size: 0.9rem"
                        @click="closeModal">X</button>
                </div>
                <div class="modal-body p-4">
                    <CadastrarClienteResumoForm :closeModal="closeModal" :modalOpened="modalCadastrarClienteOpened" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import ArgonPagination from "@/components/ArgonPagination.vue";
import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import VisualizarClienteForm from "../clientes/VisualizarClienteForm.vue";
import CadastrarClienteResumoForm from "../clientes/CadastrarClienteResumoForm.vue";
import { Modal } from 'bootstrap';

export default {
    name: 'listar-clientes-empresa',
    components: {
        ArgonPagination,
        ArgonPaginationItem,
        VisualizarClienteForm,
        CadastrarClienteResumoForm
    },
    emits: ['cliente-selecionado'],
    data() {
        return {
            currentPage: 1,
            pageSize: 4,
            user: JSON.parse(localStorage.getItem('userData')) || {},
            clientes: [],
            idClienteSelecionado: 0,
            totalElements: 0,
            totalPages: 0,
            modal: null,
            modalCadastrarCliente: null,
            modalOpened: false,
            modalCadastrarClienteOpened: false,
            selectedClient: null,
            clientOptions: [],
            loading: false,
        };
    },
    computed: {
        pagesToShow() {
            if (this.totalPages <= 3) {
                return Array.from({ length: this.totalPages }, (v, i) => i + 1);
            } else {
                if (this.currentPage === 1) {
                    return [1, 2, 3];
                } else if (this.currentPage === this.totalPages) {
                    return [this.totalPages - 2, this.totalPages - 1, this.totalPages];
                } else {
                    return [this.currentPage - 1, this.currentPage, this.currentPage + 1];
                }
            }
        }
    },
    mounted() {
        this.fetchClientes();
        this.modal = new Modal(this.$refs.modalVisualizarCliente);
        this.modalCadastrarCliente = new Modal(this.$refs.modalCadastrarCliente);
    },
    methods: {
        async fetchClientes(pageZero) {
            var pageNo = this.currentPage - 1;
            if (pageZero) {
                this.pageNo = 0
                this.currentPage = 1
            }

            const idEmpresa = this.user.idEmpresa;

            try {
                const url = `${process.env.VUE_APP_API_ENDPOINT}/clientes/empresa?idEmpresa=${idEmpresa}&pageNo=${pageNo}&pageSize=${this.pageSize}`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`
                    }
                });

                this.clientes = response.data.clientes;
                this.totalElements = response.data.paginacao.totalElements;
                this.totalPages = Math.ceil(this.totalElements / this.pageSize);
            } catch (error) {
                console.error('Erro ao buscar clientes:', error);
            }
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.fetchClientes();
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.handlePageChange(this.currentPage);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchClientes();
            }
        },
        cadastrarClientesEmpresa() {
            this.modalCadastrarCliente.show();
            this.modalCadastrarClienteOpened = true;
        },
        visualizar(clienteId) {
            if (clienteId) {
                this.idClienteSelecionado = clienteId;
                this.modal.show();
                this.modalOpened = true;
                this.selectedClient = null;
            }
        },
        defineCliente(cliente) {
            this.$emit('cliente-selecionado', cliente);
        },
        defineClienteFiltro(clienteSelecionadoFiltro) {
            console.log('Cliente selecionado:', clienteSelecionadoFiltro);
            const clienteSelecionado = this.clientes.find(cliente => cliente.id === clienteSelecionadoFiltro);
            if (clienteSelecionado) {
                this.$emit('cliente-selecionado', clienteSelecionado);
                console.log('Cliente selecionado encontrado:', clienteSelecionado);
            } else {
                console.error('Cliente não encontrado para o ID:', this.selectedClient);
            }
        },
        closeModal() {
            this.modal.hide();
            this.modalOpened = false;
            this.modalCadastrarCliente.hide();
            this.modalCadastrarClienteOpened = false;
            this.fetchClientes(true);
        },
        fetchClientesFiltro(query) {

            this.loading = true;
            axios.get(`${process.env.VUE_APP_API_ENDPOINT}/clientes/empresa`, {
                params: {
                    idEmpresa: this.user.idEmpresa,
                    nome: query,
                    pageNo: 0,
                    pageSize: this.pageSize
                },
                headers: {
                    Authorization: `Bearer ${this.user.token}`
                }
            })
                .then(response => {
                    this.clientOptions = response.data.clientes.map(cliente => ({
                        value: cliente.id,
                        label: `${cliente.nome} | Contato: ${cliente.telefone || ''}`
                    }));
                    this.loading = false;
                    this.clientes = response.data.clientes;
                })
                .catch(error => {
                    console.error('Erro ao buscar clientes:', error);
                    this.loading = false;
                });

        }
    }
};
</script>


<style scoped>
.text-success {
    color: #28a745 !important;
}

.text-danger {
    color: #dc3545 !important;
}

h3 {
    padding-top: 5px;
    font-size: 1rem;
}

.show-mobile {
  display: none;
}

@media (max-width: 768px) {
    h3 {
        font-size: 1.0rem;
    }

    .btn-responsive {
        margin-top: 5px;
        font-size: 0.775rem;
        padding: 0.65rem 0.6rem;
    }

    .show-mobile {
        display: flow;
    }
}

@media (max-width: 435px) {
    .btn-responsive {
        font-size: 0.775rem;
        padding: 0.65rem 0.8rem;
    }

    .show-mobile {
        display: flow;
    }

    .nome-width {
        min-width: 230px;
    }
}
</style>