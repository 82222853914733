<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-10 pt-2 pb-10 m-3 border-radius-lg"
      style="
        background-color: rgb(236 236 236);
        background-position: top;
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mt-6">Not found!</h1>
          </div>
        </div>
      </div>
    </div>
    

  </main>
</template>

<script>
import { useStore } from "vuex";
import 'vue3-form-wizard/dist/style.css'

export default {
  name: "BemVindo",
  data() {
    return {
      body: document.getElementsByTagName("body")[0],
      
    };
  },
  mounted() {
    const store = useStore();
    store.state.hideConfigButton = true;
    store.state.showNavbar = false;
    store.state.showSidenav = false;
    store.state.showFooter = false;
    this.body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    const store = useStore();
    store.state.hideConfigButton = false;
    store.state.showNavbar = true;
    store.state.showSidenav = true;
    store.state.showFooter = true;
    this.body.classList.add("bg-gray-100");
  },
}
</script>
