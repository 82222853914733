<template>
  <div class="row" v-if="this.tipoAtendimento == null">
    <div class="col-12 col-md-12 mt-0 mt-md-0 text-center">
      <h6 class="mb-0 text-md text-center">Selecione o tipo de atendimento:</h6>
      <div id="situacaoServico" class="d-flex justify-content-between flex-wrap mt-2">
        <button v-for="status in atendimentoOptions" :key="status.value"
          :class="['btn', 'flex-fill', 'm-1', 'btn-success']" @click="defineTipoAtendimento(status.value)"
          style="min-width: 80px;">
          {{ status.label }}
        </button>
      </div>
    </div>
  </div>

  <div class="row" v-if="this.tipoAtendimento == 0">
    <div class="col-12 col-md-12 mt-0 mt-md-0 ">
      <form-wizard step-size="xs" class="mt-3" color="#2dce89" ref="formWiz">
        <hr class="my-3">
        <tab-content title="">
          <h6 class="mb-0 text-md text-center">Selecione o Serviço:</h6>
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Descrição:</th>
                  <th class="text-center text-secondary opacity-7"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(servico, index) in servicos" :key="index" @click="defineServico(servico)"
                  style="cursor: pointer;">
                  <td class="nome-width">
                    <div class="d-flex px-3 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ servico.nomeServico }}</h6>
                        <h6 class="mb-0 pt-2 text-sm text-secondary text-wrap">{{ servico?.descricaoServico || '' }}
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td style="text-align: right;">
                    <button type="button" class="btn btn-responsive btn-success mt-3"
                      @click.stop="defineServico(servico)">
                      <i class="fa fa-check-circle" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button class="btn mt-3" @click="this.goBackTabInicial"
            style="background-color: rgb(45, 206, 137); border-color: rgb(45, 206, 137); color: white;"><i class="fa fa-chevron-left"></i></button>
        </tab-content>

        <tab-content title="">
          <SelecionaColaborador :modalOpened="this.buscarColaborador" :hashEmpresa="this.user?.hashEmpresa"
            :hashServico="this.servicoSelecionado?.hash" @colaborador-selecionado="defineColaborador">
          </SelecionaColaborador>
          <button class="btn" @click="this.goBackDefineColaborador"
            style="background-color: rgb(45, 206, 137); border-color: rgb(45, 206, 137); color: white;"><i class="fa fa-chevron-left"></i></button>
        </tab-content>

        <tab-content title="">
          <SelecionaClientes @cliente-selecionado="defineCliente"></SelecionaClientes>

          <button class="btn mt-1" @click="this.goBackTab"
            style="background-color: rgb(45, 206, 137); border-color: rgb(45, 206, 137); color: white;"><i class="fa fa-chevron-left"></i></button>
          <button class="btn mt-1 btn-warning" @click="this.defineCliente(null)"
            style="float:right">Ignorar e Avançar</button>
        </tab-content>

        <tab-content title="">
          <h6 class="mb-0 text-md text-center">Data e Hora:</h6>
          <div class="row">
            <div class="col-12 col-md-6 mt-3 mt-md-0 ">
              <label for="data">Data</label>
              <input type="date" id="data" class="form-control p-3" v-model="service.data" required />
            </div>
            <div class="col-12 col-md-6 mt-3 mt-md-0 ">
              <label for="hora">Horário</label>
              <input type="time" id="hora" class="form-control p-3" v-model="service.horario" required />
            </div>
          </div>
          <hr/>

          <button class="btn mt-1" @click="this.goBackTab"
            style="background-color: rgb(45, 206, 137); border-color: rgb(45, 206, 137); color: white;"><i class="fa fa-chevron-left"></i></button>
          <button class="btn mt-1 btn-success" @click="this.defineDataHora()"
            style="float:right">Próximo</button>
        </tab-content>

        <tab-content title="">
          <div class="row">
            <div class="col-12 col-md-12 mt-1 mt-md-0 ">
              <div class="card-body">
                  <h6 class="mb-0 text-lg text-center">Resumo:</h6>
                  <hr/>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-md">Cliente: {{ this.selectedClient?.nome || 'Cliente não informado' }}, {{ + this.selectedClient?.telefone || '' }}</h6>
                      <h6 class="mb-0 text-md">Colaborador: {{ selectedColaborador?.nome || '' }}</h6>
                      <h6 class="mb-0 pt-1 text-sm">Serviço: {{ selectedColaborador?.servico?.nome || '' }}</h6>
                      <h6 class="mb-0 pt-2 text-sm text-secondary text-wrap">{{ servicoSelecionado?.descricaoServico || '' }}</h6>
                      <h6 class="mb-0  pt-2 text-sm">
                        <i class="fa fa-money" aria-hidden="true"></i>
                        R$ {{ selectedColaborador?.servico?.preco?.toFixed(2) || '0.00' }}
                      </h6>
                      <h6 class="mb-0 pt-2 text-sm">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                        {{ selectedColaborador?.servico?.duracao || '0' }} min
                      </h6>
                      <h6 class="mb-0 pt-2 text-sm">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                        {{ this.formataData(this.service.data) || '' }} às {{ service?.horario || '' }}h
                      </h6>
                      
                    </div>
                  <hr/>
                  
            </div>
          </div>
          </div>

          <hr />
          <button class="btn mt-3" @click="this.goBackTab"
            style="background-color: rgb(45, 206, 137); border-color: rgb(45, 206, 137); color: white;"><i class="fa fa-chevron-left"></i></button>
          <button class="btn mt-3" @click="this.salvarAtendimento()"
            style="background-color: rgb(45, 206, 137); border-color: rgb(45, 206, 137); color: white; float:right">Finalizar</button>
        </tab-content>
      </form-wizard>
    </div>
  </div>

  <div class="row" v-if="this.tipoAtendimento == 1">
    <div class="col-12 col-md-12 mt-0 mt-md-0 ">
      <form-wizard step-size="xs" class="mt-3" color="#2dce89" ref="formWiz">
        <hr class="my-3">
        <tab-content title="">
          <h6 class="mb-0 text-md text-center">Selecione o Serviço:</h6>
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Descrição:</th>
                  <th class="text-center text-secondary opacity-7"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(servico, index) in servicos" :key="index" @click="defineServico(servico)"
                  style="cursor: pointer;">
                  <td class="nome-width">
                    <div class="d-flex px-3 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ servico.nomeServico }}</h6>
                        <h6 class="mb-0 pt-2 text-sm text-secondary text-wrap">{{ servico?.descricaoServico || '' }}
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td style="text-align: right;">
                    <button type="button" class="btn btn-responsive btn-success mt-3"
                      @click.stop="defineServico(servico)">
                      <i class="fa fa-check-circle" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button class="btn mt-3" @click="this.goBackTabInicial"
            style="background-color: rgb(45, 206, 137); border-color: rgb(45, 206, 137); color: white;"><i class="fa fa-chevron-left"></i></button>
        </tab-content>

        <tab-content title="">
          <SelecionaColaborador :modalOpened="this.buscarColaborador" :hashEmpresa="this.user?.hashEmpresa"
            :hashServico="this.servicoSelecionado?.hash" @colaborador-selecionado="defineColaborador">
          </SelecionaColaborador>
          <button class="btn" @click="this.goBackTab"
            style="background-color: rgb(45, 206, 137); border-color: rgb(45, 206, 137); color: white;"><i class="fa fa-chevron-left"></i></button>
        </tab-content>

        <tab-content title="">
          <div class="row" v-if="this.tipoAtendimento == 1">
            <div class="col-12 col-md-12 mt-0 mt-md-0 ">
              <SelecionaClientes @cliente-selecionado="defineCliente"></SelecionaClientes>
            </div>
          </div>

          <button class="btn mt-1" @click="this.goBackTab"
            style="background-color: rgb(45, 206, 137); border-color: rgb(45, 206, 137); color: white;"><i class="fa fa-chevron-left"></i></button>
          <button class="btn mt-1 btn-warning" @click="this.defineCliente(null)"
            style="float:right">Ignorar e Avançar</button>
        </tab-content>

        <tab-content title="">
          <div class="row">
            <div class="col-12 col-md-12 mt-1 mt-md-0 ">
              <div class="card-body">
                  <h6 class="mb-0 text-lg text-center">Resumo:</h6>
                  <hr/>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-md">Cliente: {{ this.selectedClient?.nome || 'Cliente não informado' }}, {{ + this.selectedClient?.telefone || '' }}</h6>
                      <h6 class="mb-0 text-md">Colaborador: {{ selectedColaborador?.nome || '' }}</h6>
                      <h6 class="mb-0 pt-1 text-sm">Serviço: {{ selectedColaborador?.servico?.nome || '' }}</h6>
                      <h6 class="mb-0 pt-2 text-sm text-secondary text-wrap">{{ servicoSelecionado?.descricaoServico || '' }}</h6>
                      <h6 class="mb-0  pt-2 text-sm">
                        <i class="fa fa-money" aria-hidden="true"></i>
                        R$ {{ selectedColaborador?.servico?.preco?.toFixed(2) || '0.00' }}
                      </h6>
                      <h6 class="mb-0 pt-2 text-sm">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                        {{ selectedColaborador?.servico?.duracao || '0' }} min
                      </h6>
                      <h6 class="mb-0 pt-2 text-sm">
                        <i class="fa fa-calendar" aria-hidden="true"></i> {{ this.formataData(null) || '' }} - Atendimento (Avulso)
                      </h6>
                      
                    </div>
                  <hr/>
                  
            </div>
          </div>
          </div>

          <hr />
          <button class="btn mt-3" @click="this.goBackTab"
            style="background-color: rgb(45, 206, 137); border-color: rgb(45, 206, 137); color: white;"><i class="fa fa-chevron-left"></i></button>
          <button class="btn mt-3" @click="this.salvarAtendimentoAvulso()"
            style="background-color: rgb(45, 206, 137); border-color: rgb(45, 206, 137); color: white; float:right">Finalizar</button>
        </tab-content>
      </form-wizard>
    </div>
  </div>


  <div class="modal fade" ref="modalCadastrarCliente" tabindex="0" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-sm mb-6">
      <div class="modal-content">
        <div class="modal-header text-xs text-secondary mb-0">
          <h5 class="modal-title" style="margin-left: 10px;">Cadastrar Cliente</h5>
          <button type="button" class="btn-close" style="color: black; font-size: 0.9rem"
            @click="closeModalCadastroCliente">X</button>
        </div>
        <div class="modal-body p-4">
          <CadastrarClienteResumoForm :closeModal="closeModalCadastroCliente"
            :modalOpened="modalCadastrarClienteOpened" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CadastrarClienteResumoForm from "../clientes/CadastrarClienteResumoForm.vue";
import { Modal } from 'bootstrap';
import { FormWizard, TabContent } from 'vue3-form-wizard'
import SelecionaColaborador from '../colaboradores/SelecionaColaborador.vue';
import SelecionaClientes from '../clientes/SelecionaClientes.vue'
import Swal from 'sweetalert2';


export default {
  components: {
    FormWizard,
    TabContent,
    CadastrarClienteResumoForm,
    SelecionaColaborador,
    SelecionaClientes
  },
  props: {
    modalOpened: {
      type: Boolean
    },
    closeModal: {
      type: Function,
      required: true
    },
    dateSelected: {
      type: String,
      required: false
    },
    timeSelected: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('userData')),
      service: {
        nomeServico: '',
        descricaoServico: '',
        tipoServico: '',
        situacao: 1,
        idEmpresa: null,
        selectedService: null,
        data: this.formataDataAtual(),
        horario: this.formatarHoraAtual() ,
        codigoTipoAgendamento: null,
      },
      atendimentoOptions: [
        { value: 0, label: 'Com Horário Agendado' },
        { value: 1, label: 'Sem Horário Agendado (Avulso)' },
      ],
      servicos: [],
      servicoSelecionado: null,
      buscarColaborador: false,
      showAlert: false,
      showAlertError: false,
      selectedClient: null,
      tipoAtendimento: null,
      clientOptions: [],
      selectedColaborador: null,
      hashColaboradorSelecionado: null,
      selectOptions: [],
      colaboradorOptions: [],
      serviceOptions: [],
      loading: false,
      modalCadastrarClienteOpened: false,
      modalCadastrarCliente: null,
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      })
    };
  },
  mounted() {
    this.modalCadastrarCliente = new Modal(this.$refs.modalCadastrarCliente);
  },
  methods: {
    fetchColaboradoresFiltro(query) {
      const pageNo = 0

      this.loadingColaborador = true;
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaboradores/empresa`, {
        params: {
          idEmpresa: this.user.idEmpresa || 1,
          nome: query,
          pageNo: pageNo,
          pageSize: this.pageSize
        },
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
        this.colaboradorOptions = response.data.colaboradores.map(colaborador => ({
          value: colaborador.id,
          label: `${colaborador.nome} | Contato: ${colaborador.telefone || ''}`
        }));
        this.loadingColaborador = false;
        this.colaboradores = response.data.colaboradores;
        this.totalElements = response.data.paginacao.totalElements;
        this.totalPages = Math.ceil(this.totalElements / this.pageSize);
      })
        .catch(() => {
          this.loadingColaborador = false;
        });

    },
    formatarHoraAtual() {
      const agora = new Date();
      const horas = String(agora.getHours()).padStart(2, '0');
      const minutos = String(agora.getMinutes()).padStart(2, '0');
      return `${horas}:${minutos}`;
    },
    formataDataAtual(){
      const hoje = new Date();
      hoje.setHours(0,0,0,0);
      const ano = hoje.getFullYear();
      const mes = String(hoje.getMonth() + 1).padStart(2, '0'); 
      const dia = String(hoje.getDate()).padStart(2, '0');
      return `${ano}-${mes}-${dia}`;
    },
    onEmployeeChange(value) {
      if (value) {
        this.fetchServicosColaborador(value);
      } else {
        this.serviceOptions = [];
      }
      this.service.selectedService = null;
    },
    goFoward() {
      this.$refs.formWiz.nextTab()
    },
    goBackTab() {
      this.$refs.formWiz.prevTab()
    },
    goBackDefineColaborador() {
      this.$refs.formWiz.prevTab()
      this.buscarColaborador = false;
    },
    goBackTabInicial() {
      this.tipoAtendimento = null;
      this.buscarColaborador = false;
    },
    reset() {
      this.$refs.formWiz.reset()
    },
    defineColaborador(colaboradorServico) {
      this.selectedColaborador = colaboradorServico;
      console.log(this.selectedColaborador)
      this.goFoward();
    },
    defineCliente(clienteSelecionado) {
      this.$nextTick(() => {
        this.selectedClient = clienteSelecionado;
        console.log(this.selectedClient)
        this.goFoward();
      });
    },
    defineDataHora() {
      if(this.service.data != null && this.service.horario != null){
        this.goFoward();
      }else{
        this.exibeAlerta('error', "Informe a Data e Hora do atendimento!")
      }
    },
    defineServico(servico) {
      this.servicoSelecionado = servico;
      // this.fetchColaboradoresRealizamServicoSelecionado(this.hashEmpresa, this.servicoSelecionado.hash);
      console.log(this.servicoSelecionado);
      this.buscarColaborador = true;
      this.goFoward();

    },
    async fetchServicos() {
      try {
        const url = `${process.env.VUE_APP_API_ENDPOINT}/servicos/empresa/externo?hash=${this.user.hashEmpresa}`;
        const response = await axios.get(url, {
        });

        this.servicos = response.data.servicos;
      } catch (error) {
        this.servicos = [];
        console.error('Erro ao buscar serviços:', error);
        this.$router.push({ name: 'NotFound' });
      }
    },
    fetchServicosColaborador() {
      this.loading = true;

      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaborador_servicos`, {
        params: {
          idColaborador: this.selectedColaborador,
          pageNo: 0,
          pageSize: 10
        },
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      }).then(response => {
        this.serviceOptions = response.data.servicos.map(servico => ({
          value: servico.id,
          label: `${servico.nomeServico} | R$: ${servico.preco}`
        }));
        this.loading = false;
      })
        .catch(error => {
          console.error('Erro ao buscar serviços do colaborador:', error);
          this.serviceOptions = [];
          this.loading = false;
        });
    },
    fetchClientesFiltro(query) {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/clientes/empresa`, {
        params: {
          idEmpresa: this.user.idEmpresa,
          nome: query,
          pageNo: 0,
          pageSize: this.pageSize
        },
        headers: {
          Authorization: `Bearer ${this.user.token}`
        }
      })
        .then(response => {
          this.clientOptions = response.data.clientes.map(cliente => ({
            value: cliente.id,
            label: `${cliente.nome} | Contato: ${cliente.telefone || ''}`
          }));
          this.loading = false;
        })
        .catch(error => {
          console.error('Erro ao buscar clientes:', error);
          this.loading = false;
        });

    },
    formataData(data) {
      let dataSelecionada = null;
      if(data == null){
        dataSelecionada = new Date();
        dataSelecionada.setHours(0,0,0,0);
      }else{
        dataSelecionada = new Date(data);
      }
      
      
      const dataCorrigida = new Date(dataSelecionada.getTime() + dataSelecionada.getTimezoneOffset() * 60000);
      return dataCorrigida.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },
    async salvarAtendimento() {
      try {

        const payload = {
          hashColaboradorServico: this.selectedColaborador?.servico?.hash,
          idCliente: this.selectedClient?.id,
          data: this.formataData(this.service?.data),
          hora: this.service?.horario,
          hashEmpresa: this.user?.hashEmpresa
        };

        const response = await axios.post(
          `${process.env.VUE_APP_API_ENDPOINT}/agenda/salvar_servico_agenda_colaborador`,
          payload, {
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        }
        );

        if (response.status === 200 || response.status === 201) {
          this.exibeAlerta('success', 'Atendimento salvo com sucesso!');
          this.goBack();
        }
      } catch (error) {
        this.exibeAlerta('error', 'Falha ao salvar o atendimento!');
        console.log(error),
        this.goBack();
      }
    },
    async salvarAtendimentoAvulso() {
      try {

        const payload = {
          hashColaboradorServico: this.selectedColaborador?.servico?.hash,
          idCliente: this.selectedClient?.id,
          hashEmpresa: this.user?.hashEmpresa,
          data: this.formataData(null),
        };

        const response = await axios.post(
          `${process.env.VUE_APP_API_ENDPOINT}/agenda/salvar_servico_avulso_colaborador`,
          payload, {
            headers: {
              Authorization: `Bearer ${this.user.token}`
            }
          }
        );

        if (response.status === 200 || response.status === 201) {
          this.exibeAlerta('success', 'Atendimento salvo com sucesso!');
          this.goBack();
        }
      } catch (error) {
        this.exibeAlerta('error', 'Falha ao salvar o atendimento!');
        console.log(error),
        this.goBack();
      }
    },
    defineTipoAtendimento(tipo) {
      this.tipoAtendimento = tipo;
    },
    cadastrarClientesEmpresa() {
      this.modalCadastrarCliente.show();
      this.modalCadastrarClienteOpened = true;
    },
    exibeAlerta(tipo, texto){
      this.toast.fire({
        icon: tipo,
        title: texto
      });
    },
    closeModalCadastroCliente() {
      this.modalCadastrarCliente.hide();
      this.modalCadastrarClienteOpened = false;
      this.selectedClient = null;
      this.selectedColaborador = null;
      this.tipoAtendimento = null;
      this.service.selectedService = null;
      this.reset();
    },
    goBack() {
      this.selectedClient = null;
      this.selectedColaborador = null;
      this.service.selectedService = null;
      this.tipoAtendimento = null;
      this.buscarColaborador = false;
      this.reset();
      this.closeModal();
    }
  },
  watch: {
    modalOpened() {
      this.fetchServicos();
    }
  }
};
</script>

<style scoped>
::v-deep(.wizard-footer-right .wizard-btn) {
  display: none !important;
}

::v-deep(.vue-form-wizard .wizard-card-footer .wizard-footer-left) {
  display: none !important;
}

::v-deep(.vue-form-wizard .wizard-nav-pills li, .vue-form-wizard .wizard-nav-pills a) {
  cursor: not-allowed;
  pointer-events: none;
}

::v-deep(.bg-gradient-dark) {
  background-image: linear-gradient(310deg, #0d3c35 0%, #10c584 100%) !important;
}

.btn-responsive {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .size-font-alert {
    font-size: 0.775rem;
  }
}
</style>
