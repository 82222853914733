<template>
  <div class="card">
    <div class="card-header pb-0 border-0">
      <div class="row">
        <div class="col-12 col-md-2">
          <h3 class="">Entradas</h3>
        </div>

        <div class="col-12 col-md-4 mt-3 mt-md-0">
          <el-select-v2 v-model="selectedColaborador" loading-text="Buscando" no-data-text="Não encontrado"
            no-match-text="Não encontrado!" clearable filterable remote :remote-method="fetchColaboradoresFiltro"
            @change="handleFiltersChange" :options="colaboradorOptions" :loading="loadingColaborador"
            placeholder="Digite o nome Colaborador para visualizar as entradas">
          </el-select-v2>
        </div>

        <div class="col-6 col-md-2 mt-2 mt-md-0">
          <select class="form-control" v-model="selectedMonth" @change="handleFiltersChange">
            <option v-for="(monthName, index) in monthNames" :key="index" :value="index + 1">{{ monthName }}
            </option>
          </select>
        </div>
        <div class="col-6 col-md-2 mt-2 mt-md-0">
          <select class="form-control" v-model="selectedYear" @change="handleFiltersChange">
            <option v-for="year in availableYears" :key="year" :value="year">{{ year }}</option>
          </select>
        </div>
        <div class="col-12 col-md-2 mt-3 mt-md-0">
          <button type="button" class="btn btn-success btn-sm w-100 w-md-auto" style="float:right;"
            @click="cadastrarAtendimento" disabled>
            Entrada Avulsa
          </button>
        </div>
      </div>
    </div>
    <hr class="my-1" />

    <div class="card-body px-0 pt-0 pb-0">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Serviço</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell">
                Colaborador</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell">
                Cliente</th>
              <th
                class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell">
                Desconto</th>
              <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7">Data/Hora</th>
              <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7 ps-2">
                Valor Final</th>

              <!-- <th class="text-center text-secondary opacity-7  d-none d-md-table-cell"></th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(entrada, index) in entradas" :key="index">
              <td class="">
                <div class="px-3 py-1 ">
                  <div class="">
                    <h6 class="mb-0 text-sm text-wrap">{{ entrada.nomeServico }}</h6>
                  </div>
                </div>
              </td>
              <td class="d-none d-md-table-cell">
                <p class="text-xs text-secondary mb-0">{{ entrada.colaboradorNome }}</p>
              </td>
              <td class="align-middle text-left text-sm d-none d-md-table-cell">
                <p class="text-xs text-secondary mb-0">{{ entrada.clienteNome }}</p>
              </td>
              <td class="align-middle text-center text-sm d-none d-md-table-cell  ">
                <p class="text-xs text-secondary mb-0">R$ {{ entrada.desconto.toFixed(2) }}</p>
              </td>
              <td class="align-middle text-center text-sm d-md-table-cell">
                <p class="text-xs text-secondary mb-0 text-wrap"> {{ entrada.data }} {{ entrada.horaInicio }}</p>
              </td>
              <td class="nome-width text-center">
                <h6 class="mb-0 text-sm">R$ {{ entrada.valorFinal.toFixed(2) }}</h6>
              </td>

              <!-- <td style="text-align: right;" class="align-middle text-center d-none d-md-table-cell">
                <button type="button" class="btn btn-responsive btn-success mt-3"
                  style="float: right; margin-right: 15px" @click.stop="visualizar(entrada.id)">Visualizar</button>
              </td> -->
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="1" class="text-left">
                <div class="ml-4  text-wrap" style="margin-left: 15px; white-space: normal;">
                  REF: {{ this.monthNames[this.selectedMonth - 1] }}/{{
            this.selectedYear }}
                </div>
              </td>
              <td colspan="6" class="text-right">
                <div class="d-flex justify-content-end mt-1 label_total">
                  Total: R$ {{ totalEntradas.toFixed(2) }}</div>
              </td>
            </tr>

            <tr>
              <td colspan="7" class="text-right">
                <div class="d-flex justify-content-end mt-1 ">
                  <argon-pagination>
                    <argon-pagination-item prev @click="prevPage" :disabled="currentPage === 1" />
                    <argon-pagination-item v-for="page in pagesToShow" :key="page" :label="String(page)"
                      :active="page === currentPage" @click="handlePageChange(page)" />
                    <argon-pagination-item next @click="nextPage" :disabled="currentPage === totalPages" />
                  </argon-pagination>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <div class="modal fade" ref="modalVisualizarAtendimento" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Visualizar Atendimento</h5>
          <button type="button" class="btn-close" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <VisualizarAtendimentoForm :atendimentoId="idAtendimentoSelecionado" :closeModal="closeModal"
            :modalOpened="modalVisualizarAtendimentoOpened" />
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" ref="modalCadastrarAtendimento" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl mb-6">
      <div class="modal-content">
        <div class="modal-header text-xs text-secondary mb-0">
          <h5 class="modal-title" style="margin-left: 10px;">Cadastrar Atendimento</h5>
          <button type="button" class="btn-close" style="color: black; font-size: 0.9rem" @click="closeModal">X</button>
        </div>
        <div class="modal-body p-4">
          <CadastrarAtendimentoForm :closeModal="closeModal" :modalOpened="modalCadastrarAtendimentoOpened" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import ArgonPagination from "@/components/ArgonPagination.vue";
import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import CadastrarAtendimentoForm from "../atendimentos/CadastrarAtendimentoForm.vue";
import VisualizarAtendimentoForm from "../atendimentos/VisualizarAtendimentoForm.vue";
import { Modal } from 'bootstrap';

export default {
  name: "listar-atendimentos",
  components: {
    ArgonPagination,
    ArgonPaginationItem,
    CadastrarAtendimentoForm,
    VisualizarAtendimentoForm
  },
  data() {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
    return {
      user: JSON.parse(localStorage.getItem('userData')) || {},
      currentPage: 1,
      pageSize: 3,
      totalElements: 0,
      totalPages: 0,
      entradas: [],
      totalEntradas: 0,
      selectedColaborador: null,
      selectedCliente: null,
      colaboradorOptions: [],
      clienteOptions: [],
      loadingColaborador: false,
      loadingCliente: false,
      idAtendimentoSelecionado: 0,
      selectedMonth: currentMonth,
      selectedYear: currentYear,
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      availableYears: [2024, 2025],
      modalCadastrarAtendimento: null,
      modalCadastrarAtendimentoOpened: false,
      modalVisualizarAtendimento: null,
      modalVisualizarAtendimentoOpened: false,
    };
  },
  computed: {
    pagesToShow() {
      if (this.totalPages <= 3) {
        return Array.from({ length: this.totalPages }, (v, i) => i + 1);
      } else {
        if (this.currentPage === 1) {
          return [1, 2, 3];
        } else if (this.currentPage === this.totalPages) {
          return [this.totalPages - 2, this.totalPages - 1, this.totalPages];
        } else {
          return [this.currentPage - 1, this.currentPage, this.currentPage + 1];
        }
      }
    }
  },
  mounted() {
    this.fetchEntradasEmpresa();
    this.modalCadastrarAtendimento = new Modal(this.$refs.modalCadastrarAtendimento);
    this.modalVisualizarAtendimento = new Modal(this.$refs.modalVisualizarAtendimento);
  },
  methods: {
    async fetchEntradasEmpresa(pageZero) {
      const idEmpresa = this.user.idEmpresa;
      const month = this.selectedMonth;
      const year = this.selectedYear;

      let pageNo = this.currentPage - 1;

      if (pageZero) {
        pageNo = 0;
        this.currentPage = 1;
      }

      let url = ''
      if (this.selectedColaborador) {
        url = `${process.env.VUE_APP_API_ENDPOINT}/financeiro/recuperar_entradas?colaboradorId=${this.selectedColaborador}&mes=${month}&ano=${year}&pageNo=${pageNo}&pageSize=${this.pageSize}`;
      } else {
        url = `${process.env.VUE_APP_API_ENDPOINT}/financeiro/recuperar_entradas?&empresaId=${idEmpresa}&mes=${month}&ano=${year}&pageNo=${pageNo}&pageSize=${this.pageSize}`;
      }

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        });

        this.entradas = response.data.entradas.map((entrada) => ({
          id: entrada.id,
          nomeServico: entrada.servicoRealizado.nomeServico,
          valorAdicional: entrada.servicoRealizado.valorAdicional,
          desconto: entrada.servicoRealizado.desconto,
          valorFinal: entrada.servicoRealizado.valorFinal,
          data: entrada.servicoRealizado.data,
          horaInicio: entrada.servicoRealizado.horaInicio,
          clienteNome: entrada.servicoRealizado.cliente.nome,
          colaboradorNome: entrada.servicoRealizado.colaborador?.nome || 'N/A',
          observacao: entrada.servicoRealizado.observacao,
        }));
        this.totalEntradas = response.data.totalEntradas;
        this.totalElements = response.data.paginacao.totalElements;
        this.totalPages = Math.ceil(this.totalElements / this.pageSize);
      } catch (error) {
        this.entradas = [];
        this.totalEntradas = 0;
        this.totalPages = 0;
        console.error('Erro ao buscar entradas financeiras:', error);
      }
    },
    fetchColaboradoresFiltro(query) {
      const pageNo = 0
      if (query) {
        this.loadingColaborador = true;
        axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaboradores/empresa`, {
          params: {
            idEmpresa: this.user.idEmpresa || 1,
            nome: query,
            pageNo: pageNo,
            pageSize: this.pageSize
          },
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        })
          .then(response => {
            this.colaboradorOptions = response.data.colaboradores.map(colaborador => ({
              value: colaborador.id,
              label: `${colaborador.nome} | Contato: ${colaborador.telefone || ''}`
            }));
            this.loadingColaborador = false;
            this.colaboradores = response.data.colaboradores;
            this.totalElements = response.data.paginacao.totalElements;
            this.totalPages = Math.ceil(this.totalElements / this.pageSize);
          })
          .catch(() => {
            this.loadingColaborador = false;
          });
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchEntradasEmpresa();
    },
    handleFiltersChange() {
      this.currentPage = 1;
      this.fetchEntradasEmpresa();
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchEntradasEmpresa();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchEntradasEmpresa();
      }
    },
    cadastrarAtendimento() {
      this.modalCadastrarAtendimento.show();
      this.modalCadastrarAtendimentoOpened = true;
    },
    visualizar(atendimentoId) {
      if (atendimentoId) {
        this.idAtendimentoSelecionado = atendimentoId;
        this.modalVisualizarAtendimento.show();
        this.modalVisualizarAtendimentoOpened = true;
      }
    },
    closeModal() {
      this.modalCadastrarAtendimento.hide();
      this.modalCadastrarAtendimentoOpened = false;
      this.modalVisualizarAtendimento.hide();
      this.modalVisualizarAtendimentoOpened = false;
      this.fetchEntradasEmpresa(true);
    },
    getBackgroundColor(situacao) {
      switch (situacao) {
        case 'Concluído':
          return '#2dce89';
        case 'Agendado':
          return '#4284f5';
        case 'Cancelado - Cliente':
          return '#cf4104';
        case 'Cancelado - Colaborador':
          return '#cf4104';
        default:
          return '#047acf';
      }
    },
  }
};
</script>

<style scoped>
.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
}

h3 {
  font-size: 1.3rem;
}

.status-custom {
  color: #fff;
  font-size: 0.775rem;
  padding: 0.65rem 0.6rem;
  border-radius: 10px;
}

.label_total {
  margin-right: 60px
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.0rem;
  }

  .btn-responsive {
    margin-top: 5px;
    font-size: 0.775rem;
    padding: 0.65rem 0.6rem;
  }
}

@media (max-width: 414px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }

  .nome-width {
    max-width: 0px;
  }

  .info_width {
    width: 100px;
  }

  .label_total {
    margin-right: 14px
  }
}
</style>
