<template>
  <div class="card">
    <div class="card-header pb-0 border-0">
      <div class="row">
        <div class="col-12 col-md-4">
          <h3 class="">Atendimentos</h3>
        </div>

        <div class="col-12 col-md-10 mt-3 mt-md-0">
          <el-select-v2 v-model="selectedColaborador" loading-text="Buscando" no-data-text="Não encontrado"
            no-match-text="Não encontrado!" clearable filterable remote :remote-method="fetchColaboradoresFiltro"
            @change="handleFiltersChange" :options="colaboradorOptions" :loading="loadingColaborador"
            placeholder="Digite o nome do Colaborador para buscar">
          </el-select-v2>
        </div>

        <div class="col-12 col-md-2 mt-3 mt-md-0">
          <button type="button" class="btn btn-success btn-sm w-100 w-md-auto" style="float:right;"
            @click="cadastrarAtendimento">
            Novo
          </button>
        </div>
      </div>
    </div>
    <hr class="my-1" />

    <div class="card-body px-0 pt-0 pb-0">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-wrap text-secondary text-xxs font-weight-bolder opacity-7">Atendimento</th>
              <th
                class="text-uppercase text-wrap text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell">
                Colaborador</th>
              <th
                class="text-uppercase text-wrap text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell">
                Serviço</th>
              <th class="text-uppercase text-wrap text-secondary text-center d-none d-md-table-cell text-xxs font-weight-bolder opacity-7">Data/Hora</th>
              <th class="text-uppercase text-wrap text-secondary text-xxs font-weight-bolder opacity-7">Opções</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(atendimento, index) in atendimentos" :key="index" @click="visualizar(atendimento.id)"
              style="cursor: pointer;">
              <td class="nome-width text-wrap">
                <div class="d-flex px-3 py-1 ">
                  <div class="d-flex flex-column justify-content-center">  
                    <h6 class="mb-0 text-sm"> <i class="fa fa-user pr-2" aria-hidden="true"></i> {{ atendimento.cliente.nome }}</h6>
                    <h6 class="show-mobile text-xs mt-1"> <i class="fa fa-flag-o" aria-hidden="true"></i> {{ atendimento.nomeServico }}</h6>
                    <h6 class="show-mobile text-xs"> <i class="fa fa-calendar" aria-hidden="true"></i> {{ atendimento.data }} {{ atendimento.codigoTipoAgendamento == 0 ? ' às ' : '' }} {{ atendimento.horaInicio }}</h6>
                    <h6 class="show-mobile text-secondary text-xs"> <i class="fa fa-hand-o-right" aria-hidden="true"></i> {{ atendimento.colaborador.nome }}</h6>
                    <h6 class="show-mobile text-xs"
                      :style="{ color: getBackgroundColor(atendimento.situacao) }"><i class="fa fa-info-circle" aria-hidden="true"></i> {{ atendimento.situacao }}</h6>                
                    <h6 class="show-mobile text-secondary text-xs"> <i class="fa fa-comment-o" aria-hidden="true"></i> OBS: {{ atendimento.observacao }}</h6>
                  </div>
                </div>
              </td>
              <td class="d-none text-wrap d-md-table-cell">
                <p class="text-xs text-secondary mb-0">{{ atendimento.colaborador.nome }}</p>
              </td>
              <td class="align-middle text-wrap text-left text-sm d-none d-md-table-cell">
                <p class="text-xs text-secondary mb-0">{{ atendimento.nomeServico }}</p>
              </td>
              <td class="align-middle text-wrap text-center text-sm d-none d-md-table-cell">
                <h6 class="text-xs  mb-0"> {{ atendimento.data }} <br /> {{ atendimento.horaInicio }}</h6>
              </td>
              <td style="text-align: center;">
                <button type="button" class="btn btn-responsive btn-success mt-3" @click.stop="visualizar(atendimento.id)">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="6" class="text-right">
                <div class="d-flex justify-content-end mt-3" style="margin-right: 25px;">
                  <argon-pagination>
                    <argon-pagination-item prev @click="prevPage" :disabled="currentPage === 1" />
                    <argon-pagination-item v-for="page in pagesToShow" :key="page" :label="String(page)"
                      :active="page === currentPage" @click="handlePageChange(page)" />
                    <argon-pagination-item next @click="nextPage" :disabled="currentPage === totalPages" />
                  </argon-pagination>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <div class="modal fade" ref="modalVisualizarAtendimento" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Resumo do Atendimento</h5>
          <button type="button" class="btn-close" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <VisualizarResumoAtendimentoForm :atendimentoId="idAtendimentoSelecionado" :closeModal="closeModal"
            :modalOpened="modalVisualizarAtendimentoOpened" />
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" ref="modalCadastrarAtendimento" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl mb-6">
      <div class="modal-content">
        <div class="modal-header text-xs text-secondary mb-0">
          <h5 class="modal-title" style="margin-left: 10px;">Cadastrar Atendimento</h5>
          <button type="button" class="btn-close" style="color: black; font-size: 0.9rem" @click="closeModal">X</button>
        </div>
        <div class="modal-body p-4">
          <CadastrarAtendimentoWizardForm :closeModal="closeModal" :modalOpened="modalCadastrarAtendimentoOpened" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import ArgonPagination from "@/components/ArgonPagination.vue";
import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import CadastrarAtendimentoWizardForm from "../atendimentos/CadastrarAtendimentoWizardForm.vue";
import VisualizarResumoAtendimentoForm from "../atendimentos/VisualizarResumoAtendimentoForm.vue";
import { Modal } from 'bootstrap';

export default {
  name: "listar-atendimentos",
  components: {
    ArgonPagination,
    ArgonPaginationItem,
    CadastrarAtendimentoWizardForm,
    VisualizarResumoAtendimentoForm,
  },
  data() {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
    return {
      user: JSON.parse(localStorage.getItem('userData')) || {},
      currentPage: 1,
      pageSize: 4,
      totalElements: 0,
      totalPages: 0,
      atendimentos: [],
      selectedColaborador: null,
      selectedCliente: null,
      colaboradorOptions: [],
      clienteOptions: [],
      loadingColaborador: false,
      loadingCliente: false,
      idAtendimentoSelecionado: 0,
      selectedMonth: currentMonth,
      selectedYear: currentYear,
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      availableYears: [2024, 2025],
      modalCadastrarAtendimento: null,
      modalCadastrarAtendimentoOpened: false,
      modalVisualizarAtendimento: null,
      modalVisualizarAtendimentoOpened: false,
    };
  },
  computed: {
    pagesToShow() {
      if (this.totalPages <= 3) {
        return Array.from({ length: this.totalPages }, (v, i) => i + 1);
      } else {
        if (this.currentPage === 1) {
          return [1, 2, 3];
        } else if (this.currentPage === this.totalPages) {
          return [this.totalPages - 2, this.totalPages - 1, this.totalPages];
        } else {
          return [this.currentPage - 1, this.currentPage, this.currentPage + 1];
        }
      }
    }
  },
  mounted() {
    this.fetchAgendaColaborador();
    this.modalCadastrarAtendimento = new Modal(this.$refs.modalCadastrarAtendimento);
    this.modalVisualizarAtendimento = new Modal(this.$refs.modalVisualizarAtendimento);
  },
  methods: {
    async fetchAgendaColaborador(pageZero) {
      const idEmpresa = this.user.idEmpresa;
      const month = this.selectedMonth;
      const year = this.selectedYear;

      var pageNo = this.currentPage - 1;

      if (pageZero) {
        pageNo = 0
        this.currentPage = 1
      }

      let url = "";

      try {
        if (this.selectedColaborador) {
          url = `${process.env.VUE_APP_API_ENDPOINT}/agenda/recuperar_agenda_colaborador?colaboradorId=${this.selectedColaborador}&mes=${month}&ano=${year}&pageNo=${pageNo}&pageSize=${this.pageSize}`;
        } else {
          url = `${process.env.VUE_APP_API_ENDPOINT}/agenda/recuperar_agenda_colaborador?empresaId=${idEmpresa}&mes=${month}&ano=${year}&pageNo=${pageNo}&pageSize=${this.pageSize}`;
        }
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        });

        this.atendimentos = response.data.servicos;
        this.totalElements = response.data.paginacao.totalElements;
        this.totalPages = Math.ceil(this.totalElements / this.pageSize);
      } catch (error) {
        console.error('Erro ao buscar agenda do colaborador:', error);
      }
    },
    fetchColaboradoresFiltro(query) {
      const pageNo = 0
      
        this.loadingColaborador = true;
        axios.get(`${process.env.VUE_APP_API_ENDPOINT}/colaboradores/empresa`, {
          params: {
            idEmpresa: this.user.idEmpresa,
            nome: query,
            pageNo: pageNo,
            pageSize: this.pageSize
          },
          headers: {
            Authorization: `Bearer ${this.user.token}`
          }
        })
          .then(response => {
            this.colaboradorOptions = response.data.colaboradores.map(colaborador => ({
              value: colaborador.id,
              label: `${colaborador.nome} | Contato: ${colaborador.telefone || ''}`
            }));
            this.loadingColaborador = false;
            this.colaboradores = response.data.colaboradores;
            this.totalElements = response.data.paginacao.totalElements;
            this.totalPages = Math.ceil(this.totalElements / this.pageSize);
          })
          .catch(() => {
            this.loadingColaborador = false;
          });
      
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchAgendaColaborador();
    },
    handleFiltersChange() {
      this.currentPage = 1;
      this.fetchAgendaColaborador();
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchAgendaColaborador();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchAgendaColaborador();
      }
    },
    cadastrarAtendimento() {
      this.modalCadastrarAtendimento.show();
      this.modalCadastrarAtendimentoOpened = true;
    },
    visualizar(atendimentoId) {
      if (atendimentoId) {
        this.idAtendimentoSelecionado = atendimentoId;
        this.modalVisualizarAtendimento.show();
        this.modalVisualizarAtendimentoOpened = true;
      }
    },
    async closeModal() {
      this.modalCadastrarAtendimento.hide();
      this.modalCadastrarAtendimentoOpened = false;
      this.modalVisualizarAtendimento.hide();
      this.modalVisualizarAtendimentoOpened = false;
      try {
        await this.fetchAgendaColaborador(true);
        console.log("Lista de atendimentos atualizada com sucesso.");
      } catch (error) {
        console.error("Erro ao buscar agenda do colaborador:", error);
      }
      console.log("atualizando")
    },
    getBackgroundColor(situacao) {
      switch (situacao) {
        case 'Concluído':
          return '#2dce89';
        case 'Agendado':
          return '#4284f5';
        case 'Cancelado - Cliente':
          return '#cf4104';
        case 'Cancelado - Colaborador':
          return '#cf4104';
        default:
          return '#047acf';
      }
    },
  }
};
</script>

<style scoped>
.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
}

h3 {
  font-size: 1rem;
}

.show-mobile {
  display: none;
}

::v-deep(.el-select) {
  width: 99% !important;
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.0rem;
  }

  .btn-responsive {
    margin-top: 5px;
    font-size: 0.775rem;
    padding: 0.65rem 0.6rem;
  }

  .show-mobile {
    display: flow;
  }
}

@media (max-width: 435px) {
  .btn-responsive {
    font-size: 0.775rem;
    padding: 0.65rem 0.8rem;
  }
}

</style>
